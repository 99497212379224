/* eslint-disable @typescript-eslint/ban-types */
import { createReducer, createAction } from '@reduxjs/toolkit'

interface StatisticsRecord {
  created_by: string
  orders_all: number
  orders_created: number
  orders_link_sent: number
  orders_form_submitted: number
  orders_processed: number
  orders_partially_finished: number
  orders_in_transport: number
  orders_finished: number
  orders_archived: number
}

export interface StatisticsReducer {
  statistics: StatisticsRecord[]
}

const initialState: StatisticsReducer = {
  statistics: []
}

export const FETCH_STATISTICS = createAction('statistics_reducer/fetch_statistics')
export const FETCH_STATISTICS_SUCCESS = createAction<StatisticsRecord[]>('statistics_reducer/fetch_statistics_success')
export const FETCH_STATISTICS_FAIL = createAction('statistics_reducer/fetch_statistics_fail')

export const statisticsReducer = createReducer(initialState, (builder) => {
  builder.addCase(FETCH_STATISTICS_SUCCESS, (state, action) => {
    return { ...state, statistics: action.payload }
  })
})

export default statisticsReducer
