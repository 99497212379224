import React, { useEffect, useState } from 'react'
import Layout from '../Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { CREATE_INVOICE_FROM_INVOICE_POSITIONS, FETCH_INVOICE_POSITIONS } from 'src/redux/batchInvoiceCustomerReducer'
import { useParams } from 'react-router-dom'
import { RootReducer } from 'src/redux/rootReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

interface PositionToCreate {
  orderNumber: string
  id: number
}

const InvoicePositionsScreen = (): JSX.Element => {
  const { batchInvoiceCustomerId } = useParams()
  const batchInvoiceCustomerIdInt = parseInt(batchInvoiceCustomerId ?? '', 10)
  const dispatch = useDispatch()
  const [positionsToCreate, setPositionsToCreate] = useState<PositionToCreate[]>([])
  const invoicePositions = useSelector((state: RootReducer) => state.batchInvoiceCustomerReducer.invoice_positions)

  useEffect(() => {
    dispatch(FETCH_INVOICE_POSITIONS({ customerId: batchInvoiceCustomerIdInt }))
  }, [dispatch, batchInvoiceCustomerIdInt])

  const addPosition = ({ orderNumber, id }: PositionToCreate): void => {
    if (positionsToCreate.filter((item) => item.orderNumber === orderNumber).length > 0) {
      return
    }
    setPositionsToCreate([...positionsToCreate, { orderNumber, id }])
  }

  return (
    <Layout withFilter>
      <div className="p-3">
        <h1 className="text-24 ml-6">Rechnungen</h1>
        <div className="h-3" />
        <div className="bg-white rounded-sm w-full overflow-hidden">
          <div className="border-b-4 border-orange" />
          <div className="p-3">
            <div className="border rounded-sm p-2 border-lightGray w-[8rem] min-h-[10rem]">
              {positionsToCreate.map(({ orderNumber, id }) => (
                <p key={id}>{orderNumber}</p>
              ))}
            </div>
            <div className="h-3" />
            <button
              onClick={() => {
                dispatch(
                  CREATE_INVOICE_FROM_INVOICE_POSITIONS({
                    invoicePositionIds: positionsToCreate.map((item) => item.id),
                    batchInvoiceCustomerId: batchInvoiceCustomerIdInt
                  })
                )
              }}
              className="bg-orange text-14 py-2 px-10 text-center rounded-md cursor-pointer">
              Create
            </button>
            <div className="h-3" />
            <div className="h-3" />
            <table className="w-full text-14">
              <tr>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Auftragsnummer</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Vorname und Nachname</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">PLZ (von)</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">PLZ (nach)</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Erstellungsdatum</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold"></td>
              </tr>
              {invoicePositions.map((item, idx) => {
                const { id, order_number, company_name, zip_to, zip_from, created_at } = item
                return (
                  <tr key={id} className={`${idx % 2 === 0 ? 'bg-grayBackground' : 'bg-white'}`}>
                    <td className="p-2 border border-[#f4f4f4]">{order_number}</td>
                    <td className="p-2 border border-[#f4f4f4]">{company_name}</td>
                    <td className="p-2 border border-[#f4f4f4]">{zip_from}</td>
                    <td className="p-2 border border-[#f4f4f4]">{zip_to}</td>
                    <td className="p-2 border border-[#f4f4f4]">{created_at}</td>
                    <td className="p-2 border border-[#f4f4f4]">
                      <div
                        className="flex justify-center items-center text-blue cursor-pointer"
                        onClick={() => {
                          addPosition({ orderNumber: order_number, id })
                        }}>
                        <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                      </div>
                    </td>
                  </tr>
                )
              })}
            </table>
          </div>
        </div>
        <div className="h-4" />
      </div>
    </Layout>
  )
}

export default InvoicePositionsScreen
