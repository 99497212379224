import React from 'react'
import { useSelector } from 'react-redux'
import { RootReducer } from 'src/redux/rootReducer'
import { useParams } from 'react-router-dom'
import { personalTypeMapper } from 'src/helpers/personalTypeMapper'
import { Address } from '../../types'

interface AddressColumnProps {
  type: 'from_address' | 'to_address'
}

const AddressColumn = ({ type }: AddressColumnProps): JSX.Element => {
  const { id: orderId } = useParams()
  const order = useSelector((state: RootReducer) => state.orderReducer.orders[orderId ?? ''])
  if (typeof order === 'undefined') {
    return <div>Loading...</div>
  }
  const address = order[type] as Address

  return (
    <div className="flex-1 max-w-[500px] min-w-[400px]">
      <div className="px-2 flex-1">
        <div className="bg-white border-t-2 border-orange rounded-md p-4 flex-1">
          <div className="h-3" />
          <p className="text-18">{type === 'from_address' ? 'Absender' : 'Empfänger'}</p>
          <div className="h-3" />
          <div className="border border-grayBackground rounded-sm">
            <div className="flex border-b border-grayBackground">
              <div className="flex-1 border-r border-grayBackground p-1 text-darkGrey px-2">Geschlecht</div>
              <div className="flex-1 p-1 px-2 text-darkGrey">{personalTypeMapper(address.personal_type)}</div>
            </div>
            <div className="flex border-b border-grayBackground">
              <div className="flex-1 border-r border-grayBackground p-1 text-darkGrey px-2">Name / Firma</div>
              <div className="flex-1 p-1 px-2 text-darkGrey">{address.name}</div>
            </div>
            <div className="flex border-b border-grayBackground">
              <div className="flex-1 border-r border-grayBackground p-1 text-darkGrey px-2">Adresse</div>
              <div className="flex-1 p-1 px-2 text-darkGrey">
                {address.street} {address.street_number}
              </div>
            </div>
            <div className="flex border-b border-grayBackground">
              <div className="flex-1 border-r border-grayBackground p-1 text-darkGrey px-2">PLZ</div>
              <div className="flex-1 p-1 px-2 text-darkGrey">{address.zip}</div>
            </div>
            <div className="flex border-b border-grayBackground">
              <div className="flex-1 border-r border-grayBackground p-1 text-darkGrey px-2">Stadt</div>
              <div className="flex-1 p-1 px-2 text-darkGrey">{address.city}</div>
            </div>
            <div className="flex border-b border-grayBackground">
              <div className="flex-1 border-r border-grayBackground p-1 text-darkGrey px-2">Email</div>
              <div className="flex-1 p-1 px-2 text-darkGrey">{address.email}</div>
            </div>
            <div className="flex border-b border-grayBackground">
              <div className="flex-1 border-r border-grayBackground p-1 text-darkGrey px-2">Mobiltelefonnummer</div>
              <div className="flex-1 p-1 px-2 text-darkGrey">{address.first_phone}</div>
            </div>
            <div className="flex border-b border-grayBackground">
              <div className="flex-1 border-r border-grayBackground p-1 text-darkGrey px-2">2. Telefonnummer</div>
              <div className="flex-1 p-1 px-2 text-darkGrey">{address.second_phone}</div>
            </div>
            <div className="flex border-b border-grayBackground">
              <div className="flex-1 border-r border-grayBackground p-1 text-darkGrey px-2">Name der Route</div>
              <div className="flex-1 p-1 px-2 text-darkGrey">{order.route_names}</div>
            </div>
          </div>
        </div>
        {type === 'from_address' && (
          <div className="bg-white border-t-2 border-orange p-4 flex-1">
            <div className="h-3" />
            <p className="text-18">Kommentar</p>
            <div className="h-3" />
            <div className="border border-grayBackground rounded-sm">
              <div className="flex border-b border-grayBackground">
                <div className="flex-1 p-1 px-2 text-darkGrey min-h-[24px]">{order.comment}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AddressColumn
