import React from 'react'
import OrderButton from '../atoms/OrderButton'
import { useDispatch, useSelector } from 'react-redux'
import {
  faFileInvoice,
  faFileMedical,
  faMailForward,
  faPrint,
  faRemove,
  faTruck,
  faUndo,
  faWarning
} from '@fortawesome/free-solid-svg-icons'
import { RootReducer } from 'src/redux/rootReducer'
import { useParams } from 'react-router-dom'
import { UPDATE_ORDER, RESET_ORDER } from 'src/redux/orderReducer'

const navigate = (path: string): void => {
  // it will be replaced with react-router-dom useNavigate
  window.location.href = path
}

const OrderButtons = (): JSX.Element => {
  const { id: orderId } = useParams()
  const dispatch = useDispatch()
  const order = useSelector((state: RootReducer) => state.orderReducer.orders[orderId ?? '0'])
  if (typeof order === 'undefined') {
    return <></>
  }

  return (
    <div className="flex flex-row gap-2 mb-2 flex-wrap w-[33rem]">
      <OrderButton
        icon={faFileInvoice}
        disabled={order.status !== 'in_transport'}
        onClick={() => {
          navigate(`/invoices/new?order_id=${order.id}`)
        }}
        label="Rechnung erstellen"
      />
      <OrderButton
        icon={faTruck}
        disabled={order.from_address?.route === null}
        onClick={() => {
          navigate(`/routes/${order.from_address?.route?.id ?? 0}/edit`)
        }}
        label="zur von Route"
      />
      <OrderButton
        icon={faTruck}
        disabled={order.to_address?.route === null}
        onClick={() => {
          navigate(`/routes/${order.to_address?.route?.id ?? 0}/edit`)
        }}
        label="zur nach Route"
      />
      <OrderButton
        icon={faMailForward}
        disabled={!['form_submitted'].includes(order.status)}
        onClick={() => {
          dispatch(UPDATE_ORDER({ orderId: order.id, data: { status: 'processed' } }))
        }}
        label="zur Planung hinzufügen"
      />
      <OrderButton
        icon={faRemove}
        disabled={['created', 'processed', 'link_sent', 'form_submitted'].includes(order.status)}
        onClick={() => {
          dispatch(UPDATE_ORDER({ orderId: order.id, data: { status: 'deleted' } }))
        }}
        label="Löschen"
      />
      <OrderButton
        icon={faUndo}
        disabled={!['deleted'].includes(order.status)}
        onClick={() => {
          dispatch(UPDATE_ORDER({ orderId: order.id, data: { status: 'created' } }))
        }}
        label="Rückgängig"
      />
      <OrderButton
        icon={faPrint}
        disabled={order.transport_document === null}
        onClick={() => {
          window.location.href = order.transport_document ?? ''
        }}
        label="Transportdokument drucken"
      />

      <OrderButton
        icon={faWarning}
        disabled={order.transport_document === null}
        onClick={() => {
          const confirm = window.confirm(
            `Diese Option wird erstellt, um die Druckrechnung zu erzwingen.
             Die Bestellung hat einen Archivstatus, und es ist nicht möglich, wiederherzustellen`
          )
          if (confirm) {
            navigate(`/invoices/new?order_id=${order.id}`)
          }
        }}
        label="Rechnung erstellen"
      />

      <OrderButton
        icon={faFileMedical}
        disabled={false}
        onClick={() => {
          navigate(`/invoice_positions/new?order_id=${order.id}`)
        }}
        label="Multi invoice"
      />

      <OrderButton
        icon={faUndo}
        disabled={!['finished', 'in_transport'].includes(order.status)}
        onClick={() => {
          dispatch(RESET_ORDER({ id: order.id }))
        }}
        label="Zurück zum Angebot angenommen"
      />
    </div>
  )
}

export default OrderButtons
