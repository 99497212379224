import { PaymentType } from '../types'

export const paymentTypeMapper = (paymentType: PaymentType): string => {
  switch (paymentType) {
    case 'transfer':
      return 'Überweisung'
    case 'cash':
      return 'Bar'
    default:
      return 'Unbekannt'
  }
}
