/* eslint-disable @typescript-eslint/ban-types */
import { createReducer, createAction, PayloadAction } from '@reduxjs/toolkit'
import { ListRoute, Route } from '../types/index'

export interface RoutesReducer {
  routes: ListRoute[]
  total_pages: number
  total_count: number
  currentPage: number
}

const initialState: RoutesReducer = {
  routes: [],
  total_pages: 0,
  total_count: 0,
  currentPage: 1
}

export const FETCH_ROUTES = createAction('routes_reducer/fetch_routes')
interface FetchInvoiceSuccessPayload {
  total_pages: number
  total_count: number
  routes: ListRoute[]
}
export const FETCH_ROUTES_SUCCESS = createAction<FetchInvoiceSuccessPayload>('routes_reducer/fetch_routes_success')
export const FETCH_ROUTES_FAIL = createAction('routes_reducer/fetch_routes_fail')

export const NEXT_PAGE = createAction('routes_reducer/next_page')
export const PREVIOUS_PAGE = createAction('routes_reducer/previous_page')

export interface ArchiveRoutePayload {
  id: number
}
export type ArchiveRouteAction = PayloadAction<ArchiveRoutePayload>
export const ARCHIVE_ROUTE = createAction<ArchiveRoutePayload>('routes_reducer/archive_route')
export const ARCHIVE_ROUTE_SUCCESS = createAction<Route>('routes_reducer/archive_route_success')
export const ARCHIVE_ROUTE_FAIL = createAction('routes_reducer/archive_route_fail')

interface SetPagePayload {
  page: number
}
export const SET_PAGE = createAction<SetPagePayload>('routes_reducer/set_page')

export const routesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(FETCH_ROUTES_SUCCESS, (state, action) => {
      return { ...state, ...action.payload }
    })
    .addCase(NEXT_PAGE, (state, action) => {
      if (state.currentPage >= state.total_pages) {
        return state
      }
      return { ...state, currentPage: state.currentPage + 1 }
    })
    .addCase(PREVIOUS_PAGE, (state, action) => {
      if (state.currentPage === 1) {
        return state
      }
      return { ...state, currentPage: state.currentPage - 1 }
    })
    .addCase(SET_PAGE, (state, action) => {
      return { ...state, currentPage: action.payload.page }
    })
    .addCase(ARCHIVE_ROUTE_SUCCESS, (state, action) => {
      return { ...state, routes: state.routes.filter((route) => route.id !== action.payload.id) }
    })
})

export default routesReducer
