import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

interface Props {
  disabled?: boolean
  onClick: () => void
  label: string
  icon: IconDefinition
}

const OrderButton = ({ disabled = false, onClick, label, icon }: Props): JSX.Element => {
  const handleClick = (): void => {
    if (disabled) {
      return
    }
    onClick()
  }

  return (
    <div
      onClick={handleClick}
      className={`bg-white rounded-md text-14 flex flex-col items-center justify-center ${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      } group overflow-hidden`}>
      <div className="flex flex-col items-center justify-center px-2">
        <div className={disabled ? 'text-lightGray' : 'text-darkGrey'}>{label}</div>
        <div className={disabled ? 'text-lightGray' : 'text-darkGrey'}>
          <FontAwesomeIcon size="xl" icon={icon} />
        </div>
      </div>
      <div className="h-1 bg-white w-full group-hover:bg-orange" />
    </div>
  )
}

export default OrderButton
