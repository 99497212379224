import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

interface Props {
  type: 'start' | 'end'
  duration?: string
  distance?: string
}

const RouteStaticElement = ({ type, duration = '', distance = '' }: Props): JSX.Element => {
  return (
    <div className="bg-white rounded-sm w-[20rem] border border-blue mb-1 p-3">
      <div className="flex flex-row justify-between">
        <p className="text-16 font-semibold">{type === 'start' ? 'Start' : 'End'}</p>
        <p className="text-14 max-w-[9rem] text-right">
          {duration} {distance}
        </p>
      </div>
      <div className="h-[1rem]" />
      <div className="flex flex-row justify-between">
        <div>
          <div>
            <FontAwesomeIcon size="xs" icon={faPhone} />
          </div>
          <p className="text-14">{'232323223'}</p>
        </div>
        <div>
          <div className="text-14 font-semibold">Name:</div>
          <p className="text-14">{type === 'start' ? 'Start' : 'End'}</p>
        </div>
        <div>
          <div className="text-14 font-semibold">Zip:</div>
          <p className="text-14">{'38350'}</p>
        </div>
      </div>
    </div>
  )
}

export default RouteStaticElement
