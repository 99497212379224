import React from 'react'
import { Field } from 'react-final-form'
import { required } from 'src/helpers/required'

interface Option {
  label: string
  value: string
}

interface Props {
  name: string
  options: Option[]
}

const FinalFormRatio = ({ name, options }: Props): JSX.Element => {
  return (
    <>
      {options.map((item) => (
        <label key={item.value} htmlFor={name + item.value} className="text-16 block">
          <Field
            validate={required}
            name={name}
            component="input"
            className="mr-1"
            id={name + item.value}
            type="radio"
            value={item.value}
          />
          {item.label}
        </label>
      ))}
    </>
  )
}

export default FinalFormRatio
