import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { UPDATE_ORDER } from 'src/redux/orderReducer'
import { RootReducer } from 'src/redux/rootReducer'

const PriceInput = (): JSX.Element => {
  const { id: orderId } = useParams()
  const reducerPrice = useSelector((state: RootReducer) => state.orderReducer.orders[orderId ?? '0']?.price)
  const [price, setPrice] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    setPrice(reducerPrice)
  }, [reducerPrice])

  return (
    <div className="flex-1 flex">
      <div className="flex-1 flex items-center">
        <div className="text-gray border px-3 py-2 border-lightGray border-r-0 text-14">€</div>
        <input
          value={price}
          onChange={(e) => {
            setPrice(e.target.value)
          }}
          type="number"
          className="border py-2 border-lightGray w-full px-2 text-14"
        />
      </div>
      <button
        onClick={() => {
          dispatch(UPDATE_ORDER({ data: { price }, orderId: parseInt(orderId ?? '0', 10) }))
        }}
        className="text-white px-2 py-1 bg-blue border-blue border text-14">
        Submit price
      </button>
    </div>
  )
}

export default PriceInput
