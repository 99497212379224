import React, { useEffect } from 'react'
import Layout from '../Layout/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducer } from 'src/redux/rootReducer'
import { FETCH_BATCH_INVOICE_CUSTOMERS } from 'src/redux/batchInvoiceCustomersReducer'
import { NavLink, useNavigate } from 'react-router-dom'

const BatchCustomerScreen = (): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const batchInvoiceCustomers = useSelector(
    (state: RootReducer) => state.batchInvoiceCustomersReducer.batch_invoice_customers
  )
  useEffect(() => {
    dispatch(FETCH_BATCH_INVOICE_CUSTOMERS())
  }, [dispatch])

  return (
    <Layout>
      <div className="p-3">
        <h1 className="text-24 ml-6">Sammelrechnung Kunde</h1>
        <div className="h-3" />
        <div className="bg-white rounded-sm w-full overflow-hidden">
          <div className="border-b-4 border-orange" />
          <div className="p-3">
            <table className="w-full text-14">
              <tr>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Name der Firma</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Straße</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Hausnummer</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Stadt</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">PLZ</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold"></td>
              </tr>
              {batchInvoiceCustomers.map(({ id, company_name, street, street_number, city, zip_code }, idx) => (
                <tr
                  onClick={() => {
                    navigate(`/batch_customer/${id}/invoice_positions`)
                  }}
                  key={id}
                  className={`${idx % 2 === 0 ? 'bg-grayBackground' : 'bg-white'} cursor-pointer`}>
                  <td className="p-2 border border-[#f4f4f4]">{company_name}</td>
                  <td className="p-2 border border-[#f4f4f4]">{street}</td>
                  <td className="p-2 border border-[#f4f4f4]">{street_number}</td>
                  <td className="p-2 border border-[#f4f4f4]">{city}</td>
                  <td className="p-2 border border-[#f4f4f4]">{zip_code}</td>
                  <td
                    className="p-2 border border-[#f4f4f4]"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      navigate(`/batch_customer/${id}/edit`)
                    }}>
                    <div className="flex-1 justify-center flex items-center cursor-pointer">
                      <FontAwesomeIcon icon={faEdit} />
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
        <div className="h-4" />
        <NavLink className="text-14 text-blue" to="/batch_customer/new">
          Der neue Sammelrechnung Kunde
        </NavLink>
      </div>
    </Layout>
  )
}

export default BatchCustomerScreen
