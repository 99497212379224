/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable complexity */
import React, { useEffect } from 'react'
import Layout from '../Layout/Layout'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FETCH_ORDER, UPDATE_ORDER } from 'src/redux/orderReducer'
import { Field, Form } from 'react-final-form'
import EditOrderMainData from '../molecules/EditOrderMainData'
import { RootReducer } from 'src/redux/rootReducer'
import EditOrderAddress from '../molecules/EditOrderAddress'
import FinalFormRatio from '../atoms/FinalFormRatio'
import OrderInput from '../atoms/OrderInput'

const SingleOrderEdit = (): JSX.Element => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(FETCH_ORDER({ id: parseInt(id ?? '0', 10) }))
  }, [id, dispatch])

  const order = useSelector((state: RootReducer) => state.orderReducer.orders[id ?? '0'])

  if (typeof order === 'undefined') {
    return <div>Loading...</div>
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (values: any): void => {
    const normalizedValues = {
      ...values,
      address_from: {
        ...values.from_address,
        zip: values.from_address_zip,
        street: values.from_address_street,
        street_number: values.from_address_street_number,
        city: values.from_address_city,
        email: values.from_address_email,
        first_phone: values.from_address_phone,
        second_phone: values.from_address_second_phone,
        personal_type: values.from_address_personal_type,
        name: values.from_address_name
      },
      address_to: {
        ...values.to_address,
        zip: values.to_address_zip,
        street: values.to_address_street,
        street_number: values.to_address_street_number,
        city: values.to_address_city,
        email: values.to_address_email,
        first_phone: values.to_address_phone,
        second_phone: values.to_address_second_phone,
        personal_type: values.to_address_personal_type,
        name: values.to_address_name
      },
      address_invoice: {
        ...values.invoice_address,
        zip: values.invoice_address_zip,
        street: values.invoice_address_street,
        street_number: values.invoice_address_street_number,
        city: values.invoice_address_city,
        email: values.invoice_address_email,
        first_phone: values.invoice_address_phone,
        second_phone: values.invoice_address_second_phone,
        personal_type: values.invoice_address_personal_type,
        name: values.invoice_address_name
      }
    }
    dispatch(UPDATE_ORDER({ orderId: parseInt(id ?? '0', 10), data: normalizedValues }))
    navigate(`/orders/${id ?? 0}`)
  }
  return (
    <Layout>
      <div className="mx-3">
        <Form
          initialValues={{
            first_name: order.first_name,
            last_name: order.last_name,
            email: order.email,
            phone: order.phone,
            mark: order.mark,
            bike_model: order.bike_model,
            from_address_name: order.from_address?.name ?? '',
            from_address_street: order.from_address?.street ?? '',
            from_address_street_number: order.from_address?.street_number ?? '',
            from_address_zip: order.from_address?.zip ?? '',
            from_address_city: order.from_address?.city ?? '',
            from_address_email: order.from_address?.email ?? '',
            from_address_phone: order.from_address?.first_phone ?? '',
            from_address_second_phone: order.from_address?.second_phone ?? '',
            from_address_personal_type: order.from_address?.personal_type ?? '',
            to_address_name: order.to_address?.name ?? '',
            to_address_street: order.to_address?.street ?? '',
            to_address_street_number: order.to_address?.street_number ?? '',
            to_address_zip: order.to_address?.zip ?? '',
            to_address_city: order.to_address?.city ?? '',
            to_address_email: order.to_address?.email ?? '',
            to_address_phone: order.to_address?.first_phone ?? '',
            to_address_second_phone: order.to_address?.second_phone ?? '',
            to_address_personal_type: order.to_address?.personal_type ?? '',
            invoice_address_name: order.invoice_address?.name ?? '',
            invoice_address_street: order.invoice_address?.street ?? '',
            invoice_address_street_number: order.invoice_address?.street_number ?? '',
            invoice_address_zip: order.invoice_address?.zip ?? '',
            invoice_address_city: order.invoice_address?.city ?? '',
            invoice_address_email: order.invoice_address?.email ?? '',
            invoice_address_phone: order.invoice_address?.first_phone ?? '',
            invoice_address_second_phone: order.invoice_address?.second_phone ?? '',
            invoice_address_personal_type: order.invoice_address?.personal_type ?? '',
            payment_type: order.payment_type ?? '',
            comment: order.comment ?? '',
            make_payment: order.make_payment ?? false,
            make_payment_price: order.make_payment_price ?? ''
          }}
          onSubmit={onSubmit}
          render={({ handleSubmit, values }) => (
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            <form onSubmit={handleSubmit}>
              <div className="h-2" />
              <EditOrderMainData />
              <div className="h-2" />
              <div className="flex gap-6">
                <EditOrderAddress prefix="from" title="Absender" />
                <EditOrderAddress prefix="to" title="Empfänger" />
              </div>
              <div className="h-2" />
              <div className="flex gap-6">
                <EditOrderAddress prefix="invoice" title="Rechnungsempfänger" />
              </div>
              <div className="h-2" />
              <div className="flex gap-6">
                <div className="flex flex-col flex-1">
                  <p className="text-22">Zahlungsform</p>
                  <div className="bg-[white] flex-1 px-2 border border-lightGray">
                    <FinalFormRatio
                      name="payment_type"
                      options={[
                        { label: 'Vorab Überweisung', value: 'transfer' },
                        { label: 'Barzahlung', value: 'cash' }
                      ]}
                    />
                  </div>
                </div>
                <div className="flex flex-col flex-1">
                  <div className="bg-[white] flex-1 px-2 border border-lightGray">
                    <div className="h-2" />
                    <div className="flex items-center gap-2">
                      <Field id="make_payment" name="make_payment" component="input" type="checkbox" />
                      <label htmlFor="make_payment" className="text-14">
                        Treuhandbetrag (Kaufsumme)
                      </label>
                    </div>
                    <div className="min-w-[200px]">
                      <Field
                        type="number"
                        name="make_payment_price"
                        component={OrderInput}
                        label="Treuhandbetrag"
                        disabled={!values.make_payment}
                      />
                    </div>
                    <div className="h-2" />
                  </div>
                </div>
              </div>
              <div className="flex gap-6">
                <div className="flex flex-col flex-1">
                  <p className="text-22">Kommentar</p>
                  <div className="bg-[white] flex-1 border border-lightGray">
                    <Field
                      rows={5}
                      name="comment"
                      type="textarea"
                      component="textarea"
                      className="w-full h-full px-2"
                    />
                  </div>
                </div>
                <div className="flex flex-col flex-1" />
              </div>
              <div className="h-2" />
              <button className="bg-white border-2 border-blue rounded-md px-2 py-1">Speichern</button>
            </form>
          )}
        />
      </div>
    </Layout>
  )
}

export default SingleOrderEdit
