import { OrderStatus } from '../types'

export const statusMapper = (status: OrderStatus): string => {
  switch (status) {
    case 'created':
      return 'Erstellt'
    case 'processed':
      return 'Bearbeitet'
    case 'partially_finished':
      return 'Teilweise abgeschlossen'
    case 'deleted':
      return 'Gelöscht'
    case 'finished':
      return 'Abgeschlossen'
    case 'archived':
      return 'Archiviert'
    case 'link_sent':
      return 'Link gesendet'
    case 'form_submitted':
      return 'Formular eingereicht'
    case 'in_transport':
      return 'In Transport'
    default:
      return 'Unbekannt'
  }
}
