import React, { useEffect } from 'react'
import Layout from '../Layout/Layout'
import { Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { FETCH_ORDER, UPDATE_ORDER } from 'src/redux/orderReducer'
import OrderDataColumn from '../molecules/OrderDataColumn'
import AddressColumn from '../molecules/AddressColumn'
import EmailsSection from '../molecules/EmailsSection'
import OrderButtons from '../molecules/OrderButtons'
import OrderNewMap from '../molecules/OrderNewMap'
const SingleOrderView = (): JSX.Element => {
  const { id } = useParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(FETCH_ORDER({ id: parseInt(id ?? '0', 10) }))
  }, [id, dispatch])

  useEffect(() => {
    dispatch(UPDATE_ORDER({ orderId: parseInt(id ?? '0', 10), data: { unread: false } }))
  }, [dispatch, id])

  return (
    <Layout>
      <div className="h-3" />
      <Link to={`/orders/${id ?? '0'}/edit`}>
        <button className="ml-2 bg-white border-2 border-blue rounded-md px-2 py-1">Bearbeiten</button>
      </Link>
      <div className="h-3" />
      <div className="flex flex-row gap-2 flex-wrap mr-4">
        <OrderDataColumn />
        <AddressColumn type="from_address" />
        <AddressColumn type="to_address" />
        <EmailsSection />
        <div className="flex flex-col h-[55rem]">
          <OrderButtons />
          <OrderNewMap />
        </div>
      </div>
    </Layout>
  )
}

export default SingleOrderView
