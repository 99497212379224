/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect } from 'react'
import Layout from '../Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootReducer } from 'src/redux/rootReducer'
import { CreateInvoicePayload, FETCH_INVOICE, UPDATE_INVOICE } from 'src/redux/invoiceReducer'
import InvoiceForm from '../molecules/InvoiceForm'

const EditInvoice = (): JSX.Element => {
  const { id } = useParams()
  const isLoading = useSelector((state: RootReducer) => state.invoiceReducer.isLoading)
  const dispatch = useDispatch()
  const invoiceData = useSelector((state: RootReducer) => state.invoiceReducer.invoices[id ?? ''])
  useEffect(() => {
    dispatch(FETCH_INVOICE({ id: parseInt(id ?? '', 10) }))
  }, [dispatch, id])

  if (typeof invoiceData === 'undefined') {
    return <></>
  }

  const initialFormValues: CreateInvoicePayload = {
    price_gross: invoiceData.price_gross.toString(),
    zip_from: invoiceData.zip_from,
    zip_to: invoiceData.zip_to,
    mark: invoiceData.mark,
    bike_model: invoiceData.bike_model,
    company_name: invoiceData.company_name,
    street: invoiceData.street,
    street_number: invoiceData.street_number,
    zip: invoiceData.zip,
    city: invoiceData.city,
    description: invoiceData.description,
    order_id: invoiceData.order_id,
    without_tax: false,
    order_number: invoiceData.order_number,
    payment_method: invoiceData.payment_method ?? 'cash',
    transferred_on: invoiceData.transferred_on ?? '',
    make_payment_gross: ''
  }

  return (
    <Layout>
      <div className="font-semibold text-22 p-3">
        <InvoiceForm
          type="edit"
          withMakePaymentField
          initialFormValues={initialFormValues}
          onSubmit={(values: CreateInvoicePayload) => {
            dispatch(UPDATE_INVOICE({ id: parseInt(id ?? '', 10), values }))
          }}
          isLoading={isLoading}
        />
      </div>
    </Layout>
  )
}

export default EditInvoice
