import React, { useState } from 'react'
import NavBar from '../molecules/NavBar'
import Header from '../molecules/Header'

interface Props {
  children: JSX.Element | JSX.Element[]
  withFilter?: boolean
}

const Layout = ({ children, withFilter = false }: Props): JSX.Element => {
  const [isMenuOpen, setIsMenuOpen] = useState(window.innerWidth > 640)
  return (
    <div className="flex overflow-x-scroll md:overflow-x-hidden">
      <div className="bg-depGraphite">
        <NavBar isOpen={isMenuOpen} />
      </div>
      <div className="flex-1 bg-grayBackground">
        <Header
          withSearch={withFilter}
          onMenuClick={() => {
            setIsMenuOpen((prev) => !prev)
          }}
        />
        {children}
      </div>
    </div>
  )
}

export default Layout
