/* eslint-disable @typescript-eslint/ban-types */
import { createReducer, createAction, PayloadAction } from '@reduxjs/toolkit'
import { Order } from 'src/types'

export interface OrderReducer {
  orders: Record<string, Order>
}

const initialState: OrderReducer = {
  orders: {}
}

interface FetchOrderPayload {
  id: number
}
export type FetchOrderActiontype = PayloadAction<FetchOrderPayload>
export const FETCH_ORDER = createAction<FetchOrderPayload>('order_reducer/fetch_order')
export const FETCH_ORDER_SUCCESS = createAction<Order>('order_reducer/fetch_order_success')
export const FETCH_ORDER_FAIL = createAction('order_reducer/fetch_order_fail')

interface UpdateOrderStatusPayload {
  data: Partial<Order>
  orderId: number
}

export type UpdateOrderStatusActiontype = PayloadAction<UpdateOrderStatusPayload>
export const UPDATE_ORDER = createAction<UpdateOrderStatusPayload>('order_reducer/update_order')
export const UPDATE_ORDER_SUCCESS = createAction<Order>('order_reducer/update_order_success')
export const UPDATE_ORDER_FAIL = createAction('order_reducer/update_order_fail')

interface ResetOrderPayload {
  id: number
}
export type ResetOrderActiontype = PayloadAction<ResetOrderPayload>
export const RESET_ORDER = createAction<ResetOrderPayload>('order_reducer/reset_order')
export const RESET_ORDER_SUCCESS = createAction<Order>('order_reducer/reset_order_success')
export const RESET_ORDER_FAIL = createAction('order_reducer/reset_order_fail')

export const orderReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(FETCH_ORDER_SUCCESS, (state, action) => {
      return { ...state, orders: { ...state.orders, [action.payload.id]: action.payload } }
    })
    .addCase(UPDATE_ORDER_SUCCESS, (state, action) => {
      return { ...state, orders: { ...state.orders, [action.payload.id]: action.payload } }
    })
    .addCase(RESET_ORDER_SUCCESS, (state, action) => {
      return { ...state, orders: { ...state.orders, [action.payload.id]: action.payload } }
    })
})
export default orderReducer
