import React, { useState, useEffect } from 'react'
import { Order } from 'src/types'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducer } from 'src/redux/rootReducer'
import { UPDATE_ORDER } from 'src/redux/orderReducer'
interface TextAreaInputProps {
  label: string
  buttonLabel: string
  fieldName: keyof Order
}

const TextAreaInput = ({ label, buttonLabel, fieldName }: TextAreaInputProps): JSX.Element => {
  const [value, setValue] = useState('')
  const { id: orderId } = useParams()
  const reducerValue = useSelector((state: RootReducer) => state.orderReducer.orders[orderId ?? '']?.[fieldName])
  const dispatch = useDispatch()

  useEffect(() => {
    setValue(reducerValue?.toString() ?? '')
  }, [reducerValue])

  return (
    <>
      <label className="font-semibold text-14">{label}</label>
      <div className="flex justify-between items-center">
        <div className="flex-1 flex">
          <div className="flex-1 flex items-center">
            <textarea
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
              }}
              rows={3}
              className="border py-1 border-lightGray w-full px-2 text-14"
            />
          </div>
          <div>
            <button
              onClick={() => {
                dispatch(UPDATE_ORDER({ data: { [fieldName]: value }, orderId: parseInt(orderId ?? '0', 10) }))
              }}
              className="text-white px-2 py-1 bg-blue border-blue border text-14">
              {buttonLabel}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default TextAreaInput
