/* eslint-disable @typescript-eslint/ban-types */
import { createReducer, createAction, PayloadAction } from '@reduxjs/toolkit'
import { Email } from '../types/index'

export interface EmailsReducer {
  emails: Record<string, Email[]>
}

const initialState: EmailsReducer = {
  emails: {}
}

interface FetchEmailsPayload {
  orderNumber: string
}
export type FetchEmailsAction = PayloadAction<FetchEmailsPayload>
export const FETCH_EMAILS = createAction<FetchEmailsPayload>('emails_reducer/fetch_emails')
interface FetchEmailsSuccessPayload {
  orderNumber: string
  emails: Email[]
}
export type FetchEmailsSuccessAction = PayloadAction<FetchEmailsSuccessPayload>
export const FETCH_EMAILS_SUCCESS = createAction<FetchEmailsSuccessPayload>('emails_reducer/fetch_emails_success')
export const FETCH_EMAILS_FAIL = createAction('emails_reducer/fetch_emails_fail')

export const emailsReducer = createReducer(initialState, (builder) => {
  builder.addCase(FETCH_EMAILS_SUCCESS, (state, action) => {
    return { ...state, emails: { ...state.emails, [action.payload.orderNumber]: action.payload.emails } }
  })
})

export default emailsReducer
