/* eslint-disable @typescript-eslint/ban-types */
import { createReducer, createAction, PayloadAction } from '@reduxjs/toolkit'
import { Invoice } from '../types/index'

export interface InvoiceReducer {
  invoices: Record<string, Invoice>
  isLoading: boolean
}

const initialState: InvoiceReducer = {
  invoices: {},
  isLoading: false
}

interface FetchInvoicePayload {
  id: number
}
export type FetchInvoiceActionType = PayloadAction<FetchInvoicePayload>
export const FETCH_INVOICE = createAction<FetchInvoicePayload>('invoice_reducer/fetch_invoice')
export const FETCH_INVOICE_SUCCESS = createAction<Invoice>('invoice_reducer/fetch_invoice_success')
export const FETCH_INVOICE_FAIL = createAction('invoice_reducer/fetch_invoice_fail')

export interface CreateInvoicePayload {
  price_gross: string
  zip_from: string
  zip_to: string
  mark: string
  bike_model: string
  company_name: string
  street: string
  street_number: string
  zip: string
  city: string
  description: string
  order_id: number
  order_number: string
  without_tax: boolean
  payment_method: string
  transferred_on: string
  make_payment_gross: string
}
export type CreateInvoiceActionType = PayloadAction<CreateInvoicePayload>
export const CREATE_INVOICE = createAction<CreateInvoicePayload>('invoice_reducer/create_invoice')
export const CREATE_INVOICE_SUCCESS = createAction<Invoice>('invoice_reducer/create_invoice_success')
export const CREATE_INVOICE_FAIL = createAction('invoice_reducer/create_invoice_fail')

export interface UpdateInvoicePayload {
  id: number
  values: CreateInvoicePayload
}
export type UpdateInvoiceActionType = PayloadAction<UpdateInvoicePayload>
export const UPDATE_INVOICE = createAction<UpdateInvoicePayload>('invoice_reducer/update_invoice')
export const UPDATE_INVOICE_SUCCESS = createAction<Invoice>('invoice_reducer/update_invoice_success')
export const UPDATE_INVOICE_FAIL = createAction('invoice_reducer/update_invoice_fail')

export const invoiceReducer = createReducer(initialState, (builder) => {
  builder.addCase(FETCH_INVOICE_SUCCESS, (state, action) => {
    return { ...state, invoices: { ...state.invoices, [action.payload.id.toString()]: action.payload } }
  })
  builder.addCase(CREATE_INVOICE, (state) => {
    return { ...state, isLoading: true }
  })
  builder.addCase(CREATE_INVOICE_SUCCESS, (state) => {
    return { ...state, isLoading: false }
  })
  builder.addCase(CREATE_INVOICE_FAIL, (state) => {
    return { ...state, isLoading: false }
  })
})

export default invoiceReducer
