import React, { useEffect } from 'react'
import Layout from '../Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { FETCH_STATISTICS } from 'src/redux/statisticsReducer'
import { RootReducer } from 'src/redux/rootReducer'

const StatisticsScreen = (): JSX.Element => {
  const dispatch = useDispatch()
  const statistics = useSelector((state: RootReducer) => state.statisticsReducer.statistics)
  useEffect(() => {
    dispatch(FETCH_STATISTICS())
  }, [dispatch])

  return (
    <Layout withFilter>
      <div className="p-3">
        <h1 className="text-24 ml-6">Statistiken</h1>
        <div className="h-3" />
        <div className="bg-white rounded-sm w-full overflow-hidden">
          <div className="border-b-4 border-orange" />
          <div className="p-3">
            <table className="w-full text-14">
              <tr>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                  Anfrage vom
                </td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">Alle</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">Anfrage</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                  Angebot versendet
                </td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                  A. angenommen
                </td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                  in Planung
                </td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                  in Abholung
                </td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                  erfolgreich zugestellt
                </td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                  in Zustellung
                </td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                  Archiwiert
                </td>
              </tr>
              {statistics.map(
                (
                  {
                    created_by,
                    orders_all,
                    orders_created,
                    orders_link_sent,
                    orders_form_submitted,
                    orders_processed,
                    orders_partially_finished,
                    orders_in_transport,
                    orders_finished,
                    orders_archived
                  },
                  idx
                ) => (
                  <tr key={created_by} className={`${idx % 2 === 0 ? 'bg-grayBackground' : 'bg-white'} cursor-pointer`}>
                    <td className="p-2 border border-[#f4f4f4]">{created_by}</td>
                    <td className="p-2 border border-[#f4f4f4]">{orders_all}</td>
                    <td className="p-2 border border-[#f4f4f4]">{orders_created}</td>
                    <td className="p-2 border border-[#f4f4f4]">{orders_link_sent}</td>
                    <td className="p-2 border border-[#f4f4f4]">{orders_form_submitted}</td>
                    <td className="p-2 border border-[#f4f4f4]">{orders_processed}</td>
                    <td className="p-2 border border-[#f4f4f4]">{orders_partially_finished}</td>
                    <td className="p-2 border border-[#f4f4f4]">{orders_in_transport}</td>
                    <td className="p-2 border border-[#f4f4f4]">{orders_finished}</td>
                    <td className="p-2 border border-[#f4f4f4]">{orders_archived}</td>
                  </tr>
                )
              )}
            </table>
          </div>
        </div>
        <div className="h-4" />
      </div>
    </Layout>
  )
}

export default StatisticsScreen
