/* eslint-disable @typescript-eslint/ban-types */
import { createReducer, createAction } from '@reduxjs/toolkit'
import { ListOrder } from '../types/index'

type SortOrder = 'asc' | 'desc'

export type SortType =
  | { change_to_submitted_form_date: SortOrder }
  | { zip_to: SortOrder }
  | { created_at: SortOrder }
  | { updated_at: SortOrder }
  | { update_to_unread: SortOrder }
  | { last_name: SortOrder }
  | { mark: SortOrder }
  | { zip: SortOrder }

export interface OrdersReducer {
  orders: ListOrder[]
  total_pages: number
  total_count: number
  currentPage: number
  sort: SortType
  search: SearchPayload
}

const initialState: OrdersReducer = {
  orders: [],
  total_pages: 0,
  total_count: 0,
  currentPage: 1,
  sort: { created_at: 'desc' },
  search: { order_number: '', date_min: '', date_max: '', email: '', phone: '', status: '', city: '', zip: '' }
}

export const FETCH_ORDERS = createAction('orders_reducer/fetch_orders')
interface FetchInvoiceSuccessPayload {
  total_pages: number
  total_count: number
  orders: ListOrder[]
}
export const FETCH_ORDERS_SUCCESS = createAction<FetchInvoiceSuccessPayload>('orders_reducer/fetch_orders_success')
export const FETCH_ORDERS_FAIL = createAction('orders_reducer/fetch_orders_fail')

export const NEXT_PAGE = createAction('orders_reducer/next_page')
export const PREVIOUS_PAGE = createAction('orders_reducer/previous_page')
interface SetPagePayload {
  page: number
}
export const SET_PAGE = createAction<SetPagePayload>('orders_reducer/set_page')
export const SET_SORT = createAction<SortType>('orders_reducer/set_sort')

interface SearchPayload {
  order_number: string
  email: string
  date_min: string
  date_max: string
  phone: string
  status: string
  city: string
  zip: string
}
export const SET_SEARCH = createAction<SearchPayload>('orders_reducer/set_search')

export const ordersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(FETCH_ORDERS_SUCCESS, (state, action) => {
      return { ...state, ...action.payload }
    })
    .addCase(NEXT_PAGE, (state, action) => {
      if (state.currentPage >= state.total_pages) {
        return state
      }
      return { ...state, currentPage: state.currentPage + 1 }
    })
    .addCase(PREVIOUS_PAGE, (state, action) => {
      if (state.currentPage === 1) {
        return state
      }
      return { ...state, currentPage: state.currentPage - 1 }
    })
    .addCase(SET_PAGE, (state, action) => {
      return { ...state, currentPage: action.payload.page }
    })
    .addCase(SET_SORT, (state, action) => {
      return { ...state, sort: action.payload }
    })
    .addCase(SET_SEARCH, (state, action) => {
      return { ...state, search: action.payload }
    })
})

export default ordersReducer
