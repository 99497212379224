import { call, put, select, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { POST } from 'src/consts/methods'

import {
  CREATE_OR_UPDATE_ROUTE,
  CREATE_OR_UPDATE_ROUTE_FAIL,
  CREATE_OR_UPDATE_ROUTE_SUCCESS,
  CreateOrUpdateRouteActionType
} from 'src/redux/routeReducer'
import { RootReducer } from 'src/redux/rootReducer'
import { navigate } from 'src/helpers/navgate'

interface RequestPayload {
  route_id: number | null
  name: string
  duration: number
  distance: number
  with_status_change: boolean
  with_notification: boolean
  route_elements: Array<{ address_id: number }>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* CreateOrUpdateRouteFlow(action: CreateOrUpdateRouteActionType): Generator<any, void, any> {
  try {
    const routeElements = yield select((state: RootReducer) =>
      state.routeReducer.activePoints.map((point) => ({ address_id: point.address_id }))
    )
    const routeName = yield select((state: RootReducer) => state.routeReducer.routeName)
    const directions: google.maps.DirectionsResult | null = yield select(
      (state: RootReducer) => state.routeReducer.directions
    )

    const totalDistance =
      directions !== null && directions.routes.length > 0
        ? directions.routes[0].legs.reduce((acc, leg) => acc + (leg.distance?.value ?? 0), 0)
        : 0

    const totalDuration =
      directions !== null && directions.routes.length > 0
        ? directions.routes[0].legs.reduce((acc, leg) => acc + (leg.duration?.value ?? 0), 0)
        : 0

    const payload: RequestPayload = {
      route_id: action.payload.routeId ?? null,
      name: routeName,
      duration: totalDuration,
      distance: totalDistance,
      with_status_change: action.payload.withStatusChange,
      with_notification: action.payload.withNotification,
      route_elements: routeElements
    }
    yield call(apiCall, {
      endpoint: '/admin/routes',
      method: POST,
      params: { route: payload }
    })
    yield put(CREATE_OR_UPDATE_ROUTE_SUCCESS())
    navigate('/routes')
  } catch (error) {
    yield put(CREATE_OR_UPDATE_ROUTE_FAIL())
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* CreateOrUpdateRouteSaga(): Generator<any, void, any> {
  yield takeLatest(CREATE_OR_UPDATE_ROUTE, CreateOrUpdateRouteFlow)
}
