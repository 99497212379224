import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { PUT } from 'src/consts/methods'
import {
  UPDATE_ORDER,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_SUCCESS,
  UpdateOrderStatusActiontype
} from 'src/redux/orderReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* UpdateOrderFlow({ payload }: UpdateOrderStatusActiontype): Generator<any, void, any> {
  try {
    const response = yield call(apiCall, {
      endpoint: `/admin/orders/${payload.orderId}`,
      method: PUT,
      params: { order: payload.data }
    })
    yield put(UPDATE_ORDER_SUCCESS(response))
  } catch (error) {
    yield put(UPDATE_ORDER_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* UpdateOrderSaga(): Generator<any, void, any> {
  yield takeLatest(UPDATE_ORDER, UpdateOrderFlow)
}
