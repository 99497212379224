import { call, put, select, takeLatest } from 'redux-saga/effects'
import { RootReducer } from 'src/redux/rootReducer'
import {
  FETCH_DIRECTIONS,
  FETCH_DIRECTIONS_FAIL,
  FETCH_DIRECTIONS_SUCCESS,
  Point,
  UPDATE_POINTS
} from 'src/redux/routeReducer'

export const baseLatLong = { lat: 52.2356733, lng: 11.0014926 }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* FetchDirectionsFlow(): Generator<any, void, any> {
  try {
    const activePoints = yield select((state: RootReducer) => state.routeReducer.activePoints)
    const directionsService = new google.maps.DirectionsService()
    const directions: google.maps.DirectionsResult = yield call(directionsService.route, {
      origin: new google.maps.LatLng(baseLatLong.lat, baseLatLong.lng),
      destination: new google.maps.LatLng(baseLatLong.lat, baseLatLong.lng),
      travelMode: google.maps.TravelMode.DRIVING,
      waypoints: activePoints.map((point: Point) => ({
        location: new google.maps.LatLng(point.lat, point.lng),
        stopover: true
      }))
    })

    yield put(FETCH_DIRECTIONS_SUCCESS({ directions: JSON.parse(JSON.stringify(directions)) }))
    yield put(UPDATE_POINTS())
  } catch (error) {
    yield put(FETCH_DIRECTIONS_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* FetchDirectionsSaga(): Generator<any, void, any> {
  yield takeLatest(FETCH_DIRECTIONS, FetchDirectionsFlow)
}
