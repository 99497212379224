/* eslint-disable @typescript-eslint/ban-types */
import { createReducer, createAction } from '@reduxjs/toolkit'
import { User } from '../types/index'

export interface UserReducer extends User {}

const initialState: UserReducer = {
  google_map_key: '',
  user_role: 'user',
  first_name: '',
  last_name: '',
  email: '',
  id: 0
}

export const FETCH_USER = createAction('user_reducer/fetch_user')
export const FETCH_USER_SUCCESS = createAction<User>('user_reducer/fetch_user_success')
export const FETCH_USER_FAIL = createAction('user_reducer/fetch_user_fail')

export const userReducer = createReducer(initialState, (builder) => {
  builder.addCase(FETCH_USER_SUCCESS, (state, action) => {
    return { ...state, ...action.payload }
  })
})
export default userReducer
