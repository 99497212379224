import React from 'react'
import { FieldMetaState, FieldInputProps } from 'react-final-form'

interface Props {
  className: string
  inputType: string
  meta: FieldMetaState<string | undefined>
  input: FieldInputProps<string | undefined>
}

const InvoiceInput = ({ className, inputType, input, meta }: Props): JSX.Element => {
  const error = (meta.touched ?? false) && Boolean(meta.error) && meta.error.length > 0
  return (
    <input
      {...input}
      type={inputType}
      className={`border ${error ? 'border-[red]' : 'border-lightGray'} text-16 px-2 py-1 text-darkGrey ${className}`}
    />
  )
}

export default InvoiceInput
