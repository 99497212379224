import React, { useMemo, useEffect } from 'react'
import Layout from '../Layout/Layout'
import Pagination from '../molecules/Pagination'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootReducer } from '../../redux/rootReducer'
import { SET_SORT, SET_SEARCH, SET_PAGE, FETCH_ORDERS } from '../../redux/ordersReducer'
import { paymentTypeMapper } from 'src/helpers/paymentTypeMapper'
import { statusMapper } from 'src/helpers/statusMapper'
import getOrderListBorderColor from 'src/helpers/getOrderListBorderColor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons'

const OrdersScreen = (): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const sort = useSelector((state: RootReducer) => state.ordersReducer.sort)
  const currentPage = useSelector((state: RootReducer) => state.ordersReducer.currentPage)
  const orders = useSelector((state: RootReducer) => state.ordersReducer.orders)
  const dispatch = useDispatch()
  const handleSortChange = (
    colName: 'change_to_submitted_form_date' | 'zip_to' | 'created_at' | 'last_name' | 'mark' | 'zip'
  ): void => {
    // eslint-disable-next-line spaced-comment, @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error
    //@ts-ignore
    const isCurrentDirAsc = sort[colName] === 'asc'
    searchParams.set('sort', `${colName}=${isCurrentDirAsc ? 'desc' : 'asc'}`)
    searchParams.set('page', '1')
    navigate(`?${searchParams.toString()}`)
  }

  useEffect(() => {
    const page = searchParams.get('page') ?? '1'
    const [sortColumn, sortDirection] = (searchParams.get('sort') ?? 'update_to_unread=desc').split('=')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore
    const setSortPayload: SortType = { [sortColumn]: sortDirection }
    dispatch(SET_SORT(setSortPayload))
    const orderNumber = searchParams.get('orderNumber')
    const dateMin = searchParams.get('dateMin')
    const dateMax = searchParams.get('dateMax')
    const email = searchParams.get('email')
    const phone = searchParams.get('phone')
    const city = searchParams.get('city')
    const zip = searchParams.get('zip')
    const [status] = searchParams.getAll('filter_statuses[]')
    dispatch(
      SET_SEARCH({
        order_number: orderNumber ?? '',
        date_min: dateMin ?? '',
        date_max: dateMax ?? '',
        email: email ?? '',
        phone: phone ?? '',
        city: city ?? '',
        zip: zip ?? '',
        status: status ?? ''
      })
    )
    dispatch(SET_PAGE({ page: parseInt(page, 10) }))
  }, [location.search, dispatch, searchParams])

  useEffect(() => {
    dispatch(FETCH_ORDERS())
  }, [dispatch, currentPage, sort])

  return (
    <Layout withFilter>
      <div className="bg-white rounded-sm w-full overflow-hidden">
        <div className="border-b-4 border-orange" />
        <div className="p-3">
          <table className="w-full text-14">
            <tr>
              <td
                onClick={() => {
                  handleSortChange('created_at')
                }}
                className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                Auftragsnummer
              </td>
              <td
                onClick={() => {
                  handleSortChange('created_at')
                }}
                className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                Anfrage vom
              </td>
              <td
                onClick={() => {
                  handleSortChange('change_to_submitted_form_date')
                }}
                className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                Beauftragt
              </td>
              <td
                onClick={() => {
                  handleSortChange('created_at')
                }}
                className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                Route Id
              </td>
              <td
                onClick={() => {
                  handleSortChange('created_at')
                }}
                className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                Route Name
              </td>
              <td
                onClick={() => {
                  handleSortChange('last_name')
                }}
                className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                Vorname und Nachname
              </td>
              <td
                onClick={() => {
                  handleSortChange('mark')
                }}
                className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                Marke
              </td>
              <td
                onClick={() => {
                  handleSortChange('created_at')
                }}
                className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                Zahlungsmethode
              </td>
              <td
                onClick={() => {
                  handleSortChange('created_at')
                }}
                className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                Status
              </td>
              <td
                onClick={() => {
                  handleSortChange('created_at')
                }}
                className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                Telefon
              </td>
              <td
                onClick={() => {
                  handleSortChange('zip')
                }}
                className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                PLZ (start)
              </td>
              <td
                onClick={() => {
                  handleSortChange('zip')
                }}
                className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                PLZ (end)
              </td>
              <td className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer"></td>
            </tr>
            {orders.map(
              (
                {
                  id,
                  order_number,
                  created_by,
                  change_to_submitted_form_date,
                  route_names,
                  route_ids,
                  first_name,
                  last_name,
                  mark,
                  bike_model,
                  payment_type,
                  status,
                  phone,
                  zip_from,
                  unread,
                  note,
                  make_payment,
                  zip_to,
                  express_transport
                },
                idx
              ) => (
                <tr
                  onClick={() => {
                    navigate(`/orders/${id}`)
                  }}
                  key={id}
                  style={{ background: express_transport ? '#f8bfba' : '' }}
                  className={`${idx % 2 === 0 ? 'bg-grayBackground' : 'bg-white'} cursor-pointer
                  ${unread ? 'font-bold' : 'font-normal'}`}>
                  <td className="border border-[#f4f4f4] h-[5rem] relative group">
                    {typeof note === 'string' && note.length > 0 && (
                      <div className="absolute bottom-[95%] right-0 w-full justify-end group-hover:flex hidden">
                        <div className="bg-orange text-white px-2 py-1 text-14 border-r border-white border-l">
                          {note}
                        </div>
                      </div>
                    )}
                    <div
                      style={{ borderLeft: `2px solid ${getOrderListBorderColor(created_by ?? 'customer')}` }}
                      className="p-2 flex-1 h-full overflow-hidden relative">
                      {order_number}
                      {typeof note === 'string' && note.length > 0 && (
                        <div className="absolute top-[-10px] right-[-10px] bg-orange w-5 h-5 rotate-45" />
                      )}
                    </div>
                  </td>
                  <td className="p-2 border border-[#f4f4f4]">{created_by}</td>
                  <td className="p-2 border border-[#f4f4f4]">{change_to_submitted_form_date}</td>
                  <td className="p-2 border border-[#f4f4f4]">{route_ids}</td>
                  <td className="p-2 border border-[#f4f4f4]">{route_names}</td>
                  <td className="p-2 border border-[#f4f4f4]">
                    {last_name} {first_name}
                  </td>
                  <td className="p-2 border border-[#f4f4f4]">
                    {mark} {bike_model}
                  </td>
                  <td className="p-2 border border-[#f4f4f4]">{paymentTypeMapper(payment_type)}</td>
                  <td className="p-2 border border-[#f4f4f4]">{statusMapper(status)}</td>
                  <td className="p-2 border border-[#f4f4f4]">{phone}</td>
                  <td className="p-2 border border-[#f4f4f4]">{zip_from}</td>
                  <td className="p-2 border border-[#f4f4f4]">{zip_to}</td>
                  <td className="p-2 border border-[#f4f4f4]">
                    {make_payment === true && <FontAwesomeIcon icon={faMoneyBill} />}
                  </td>
                </tr>
              )
            )}
          </table>
          <Pagination reducer="ordersReducer" />
        </div>
      </div>
    </Layout>
  )
}

export default OrdersScreen
