import React, { useState } from 'react'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InvoiceFilters from './InvoiceFilters'
import OrdersFilters from './OrdersFilters'
import { useLocation } from 'react-router-dom'
const Filter = (): JSX.Element => {
  const [open, setIsOpen] = useState(false)
  const { pathname } = useLocation()

  return (
    <div className="relative">
      <div
        onClick={() => {
          setIsOpen((perv) => !perv)
        }}
        className="p-4 cursor-pointer hover:!bg-darkBlue">
        <FontAwesomeIcon icon={faSearch} size="1x" color="white" />
      </div>
      <div
        className={`w-[250px] absolute bg-depGraphite right-0 text-white p-2 ${
          open ? 'translate-x-0' : 'translate-x-full -z-50'
        } transform-translate duration-1000`}>
        {pathname.includes('orders') ? <OrdersFilters /> : <InvoiceFilters />}
      </div>
    </div>
  )
}

export default Filter
