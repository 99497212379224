import React, { useMemo } from 'react'

interface Props {
  submittedFormDate: string | null
}

const SubmittedFormDataCell = ({ submittedFormDate }: Props): JSX.Element => {
  const backgroundColor = useMemo(() => {
    if (submittedFormDate === null) return ''
    const [day, month, year] = submittedFormDate.split('.').map(Number)
    const date = new Date(year, month - 1, day)
    const now = new Date()
    const diffTime = Math.abs(now.getTime() - date.getTime())
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    if (diffDays >= 10) return '#ee6055'
    if (diffDays >= 5) return '#ffbf69'
    if (diffDays >= 4) return '#d8f3dc'
    return ''
  }, [submittedFormDate])

  return (
    <td style={{ backgroundColor }} className={'p-2 border border-[#f4f4f4]'}>
      {submittedFormDate}
    </td>
  )
}

export default SubmittedFormDataCell
