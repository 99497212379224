import { call, put, select, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { GET } from 'src/consts/methods'
import { RootReducer } from 'src/redux/rootReducer'
import { FETCH_ROUTES, FETCH_ROUTES_FAIL, FETCH_ROUTES_SUCCESS } from 'src/redux/routesReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* FetchRoutesFlow(): Generator<any, void, any> {
  try {
    const currentPage = yield select((state: RootReducer) => state.routesReducer.currentPage)
    const response = yield call(apiCall, {
      endpoint: '/admin/routes',
      method: GET,
      params: { page: currentPage }
    })
    yield put(FETCH_ROUTES_SUCCESS(response))
  } catch (error) {
    yield put(FETCH_ROUTES_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* FetchRoutesSaga(): Generator<any, void, any> {
  yield takeLatest(FETCH_ROUTES, FetchRoutesFlow)
}
