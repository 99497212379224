import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { PUT } from 'src/consts/methods'
import { navigate } from 'src/helpers/navgate'
import {
  UPDATE_INVOICE,
  UPDATE_INVOICE_FAIL,
  UPDATE_INVOICE_SUCCESS,
  UpdateInvoiceActionType
} from 'src/redux/invoiceReducer'
import { Invoice } from 'src/types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* UpdateInvoiceFlow({ payload }: UpdateInvoiceActionType): Generator<any, void, any> {
  try {
    const response: Invoice = yield call(apiCall, {
      endpoint: `/admin/invoices/${payload.id}`,
      method: PUT,
      params: { invoice: payload.values }
    })
    yield put(UPDATE_INVOICE_SUCCESS(response))
    navigate(`invoices/${String(response.id)}`)
  } catch (error) {
    yield put(UPDATE_INVOICE_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* UpdateInvoiceSaga(): Generator<any, void, any> {
  yield takeLatest(UPDATE_INVOICE, UpdateInvoiceFlow)
}
