import { CreatedBy } from 'src/types'

const getOrderListBorderColor = (createdBy: CreatedBy | null): string => {
  switch (createdBy) {
    case 'estimoto':
      return '#72afd2'
    case 'spedimoto':
      return '#72d287'
    case 'mototop':
      return '#ff8300'
    default:
      return 'white'
  }
}

export default getOrderListBorderColor
