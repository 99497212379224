import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { PUT } from 'src/consts/methods'
import { ARCHIVE_ROUTE, ARCHIVE_ROUTE_FAIL, ARCHIVE_ROUTE_SUCCESS, ArchiveRouteAction } from 'src/redux/routesReducer'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* ArchiveRouteFlow(action: ArchiveRouteAction): Generator<any, void, any> {
  try {
    const response = yield call(apiCall, {
      endpoint: `/admin/routes/${action.payload.id}`,
      method: PUT,
      params: { route: { status: 'archived' } }
    })
    yield put(ARCHIVE_ROUTE_SUCCESS(response))
  } catch (error) {
    yield put(ARCHIVE_ROUTE_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* ArchiveRouteSaga(): Generator<any, void, any> {
  yield takeLatest(ARCHIVE_ROUTE, ArchiveRouteFlow)
}
