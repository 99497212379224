import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

interface Props {
  phone: string | null
}

const WhatsAppCall = ({ phone }: Props): JSX.Element => {
  if (phone === null) return <></>
  return (
    <a className="cursor-pointer" href={`https://wa.me/${phone}`}>
      <FontAwesomeIcon icon={faPhone} />
    </a>
  )
}

export default WhatsAppCall
