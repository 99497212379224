import React, { useState } from 'react'

interface Props {
  email: string
  date: string
  content: string
}

const Email = ({ email, date, content }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <div
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        className={`p-3 bg-white mt-1 rounded-md border-blue border-2 flex justify-between text-14 cursor-pointer  ${
          isOpen ? 'rounded-b-none' : ''
        }`}>
        <div>{email}</div>
        <div>{date}</div>
      </div>
      {isOpen && (
        <div className="flex justify-center p-3 bg-white rounded-md border-blue border-2 text-14 border-t-0 rounded-t-none">
          <div
            ref={(node) => {
              if (node !== null) {
                const shadowRoot = node.attachShadow({ mode: 'open' })
                shadowRoot.innerHTML = content
              }
            }}
          />
        </div>
      )}
    </>
  )
}

export default Email
