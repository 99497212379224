import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { GET } from 'src/consts/methods'
import { FETCH_ROUTE, FETCH_ROUTE_FAIL, FETCH_ROUTE_SUCCESS, FetchRouteActionType } from 'src/redux/routeReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* FetchRouteFlow(action: FetchRouteActionType): Generator<any, void, any> {
  try {
    const response = yield call(apiCall, {
      endpoint: `/admin/routes/${action.payload.id}`,
      method: GET,
      params: {}
    })
    yield put(FETCH_ROUTE_SUCCESS(response))
  } catch (error) {
    yield put(FETCH_ROUTE_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* FetchRouteSaga(): Generator<any, void, any> {
  yield takeLatest(FETCH_ROUTE, FetchRouteFlow)
}
