import React, { useEffect, useRef, useState } from 'react'

import { AdvancedMarker, APIProvider, Map, MapCameraChangedEvent, Pin } from '@vis.gl/react-google-maps'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootReducer } from 'src/redux/rootReducer'

const OrderNewMap = (): JSX.Element => {
  const [apiLoaded, setApiLoaded] = useState(false)
  const [distance, setDistance] = useState<string | null>(null)
  const [duration, setDuration] = useState<string | null>(null)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const { id: orderId } = useParams()
  const apiKey = useSelector((state: RootReducer) => state.userReducer.google_map_key)
  const addressFrom = useSelector((state: RootReducer) => state.orderReducer.orders[orderId ?? '0']?.from_address)
  const addressTo = useSelector((state: RootReducer) => state.orderReducer.orders[orderId ?? '0']?.to_address)
  const directionsRendererRef = useRef<google.maps.DirectionsRenderer | null>(null)

  useEffect(() => {
    if (apiLoaded && addressFrom !== null && addressTo !== null && map !== null) {
      const directionsService = new google.maps.DirectionsService()
      if (directionsRendererRef.current === null) {
        directionsRendererRef.current = new google.maps.DirectionsRenderer()
      }
      directionsService
        .route(
          {
            origin: new google.maps.LatLng(addressFrom.point.lat, addressFrom.point.lng),
            destination: new google.maps.LatLng(addressTo.point.lat, addressTo.point.lng),
            travelMode: google.maps.TravelMode.DRIVING
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK && directionsRendererRef.current !== null && map !== null) {
              directionsRendererRef.current.setDirections(result)
              directionsRendererRef.current.setMap(map)
              setDistance(result?.routes[0].legs[0].distance?.text ?? null)
              setDuration(result?.routes[0].legs[0].duration?.text ?? null)
            } else {
              // eslint-disable-next-line no-console, @typescript-eslint/restrict-template-expressions
              console.error(`Error fetching directions: ${status}`)
            }
          }
        )
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(`Error fetching directions ${error as string}`)
        })
    }
  }, [apiLoaded, addressFrom, addressTo, map])

  if (apiKey.length === 0 || typeof addressFrom === 'undefined' || typeof addressTo === 'undefined') {
    return <></>
  }

  return (
    <div className="w-[33rem] h-[35rem] bg-white">
      <div className="h-1 bg-orange" />
      <div className="p-2 flex justify-between items-center">
        <div>
          {addressFrom?.city} -- {addressTo?.city}
          <br />
          {duration}
          <br />
          {distance}
          <br />
        </div>
        <div>
          <a
            href={`https://www.google.com/maps?daddr=${addressFrom?.city ?? ''}&f=d&saddr=${
              addressTo?.city ?? ''
            }&source=s_d`}
            className="bg-white border-2 border-blue rounded-md px-2 py-1">
            In Google Maps öffnen
          </a>
        </div>
      </div>
      <APIProvider
        apiKey={apiKey}
        onLoad={() => {
          setApiLoaded(true)
        }}>
        {apiLoaded && (
          <Map
            onTilesLoaded={(e) => {
              setMap(e.map)
            }}
            defaultZoom={6}
            mapId="MapId-1"
            defaultCenter={{ lat: 51.165691, lng: 10.451526 }}
            onCameraChanged={(ev: MapCameraChangedEvent) => null}>
            <AdvancedMarker position={addressTo?.point}>
              <Pin background={'red'} glyphColor={'white'} borderColor={'red'} />
            </AdvancedMarker>
            <AdvancedMarker position={addressFrom?.point}>
              <Pin background={'#FBBC04'} glyphColor={'#000'} borderColor={'#000'} />
            </AdvancedMarker>
          </Map>
        )}
      </APIProvider>
    </div>
  )
}

export default OrderNewMap
