import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { GET } from 'src/consts/methods'
import { FETCH_EMAILS_SUCCESS, FetchEmailsAction, FETCH_EMAILS, FETCH_EMAILS_FAIL } from 'src/redux/emailsReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* FetchEmailsFlow(action: FetchEmailsAction): Generator<any, void, any> {
  try {
    const response = yield call(apiCall, {
      endpoint: '/emails/transport',
      method: GET,
      params: { order_number: action.payload.orderNumber }
    })
    yield put(FETCH_EMAILS_SUCCESS({ orderNumber: action.payload.orderNumber, emails: response.emails }))
  } catch (error) {
    yield put(FETCH_EMAILS_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* FetchEmailsSaga(): Generator<any, void, any> {
  yield takeLatest(FETCH_EMAILS, FetchEmailsFlow)
}
