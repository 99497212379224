import React, { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const OrdersFilters = (): JSX.Element => {
  const [orderNumber, setOrderNumber] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [zip, setZip] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [dateMin, setDateMin] = useState<string>('')
  const [dateMax, setDateMax] = useState<string>('')
  const location = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const navigate = useNavigate()

  const handleFilter = (): void => {
    searchParams.set('orderNumber', orderNumber)
    searchParams.set('email', email)
    searchParams.set('dateMin', dateMin)
    searchParams.set('dateMax', dateMax)
    searchParams.set('phone', phone)
    searchParams.set('zip', zip)
    searchParams.set('city', city)
    navigate(`?${searchParams.toString()}`)
  }

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <label className="text-lightGray text-nowrap">Auftragsnr.</label>
        </div>
        <div>
          <input
            value={orderNumber}
            onChange={(e) => {
              setOrderNumber(e.target.value)
            }}
            className="flex-1 w-[8rem] text-[black]"
            type="text"
          />
        </div>
      </div>
      <div className="h-2" />
      <div className="flex justify-between">
        <div>
          <label className="text-lightGray text-nowrap">Email</label>
        </div>
        <div>
          <input
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            className="flex-1 w-[8rem] text-[black]"
            type="text"
          />
        </div>
      </div>
      <div className="h-2" />
      <div className="flex justify-between">
        <div>
          <label className="text-lightGray text-nowrap">Datum min</label>
        </div>
        <div>
          <input
            value={dateMin}
            onChange={(e) => {
              setDateMin(e.target.value)
            }}
            className="flex-1 w-[8rem] text-[black]"
            type="date"
          />
        </div>
      </div>
      <div className="h-2" />
      <div className="flex justify-between">
        <div>
          <label className="text-lightGray text-nowrap">Datum maks</label>
        </div>
        <div>
          <input
            value={dateMax}
            onChange={(e) => {
              setDateMax(e.target.value)
            }}
            className="flex-1 w-[8rem] text-[black]"
            type="date"
          />
        </div>
      </div>
      <div className="h-2" />
      <div className="flex justify-between">
        <div>
          <label className="text-lightGray text-nowrap">Telefon</label>
        </div>
        <div>
          <input
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value)
            }}
            className="flex-1 w-[8rem] text-[black]"
            type="text"
          />
        </div>
      </div>
      <div className="h-2" />
      <div className="flex justify-between">
        <div>
          <label className="text-lightGray text-nowrap">Zip</label>
        </div>
        <div>
          <input
            value={zip}
            onChange={(e) => {
              setZip(e.target.value)
            }}
            className="flex-1 w-[8rem] text-[black]"
            type="text"
          />
        </div>
      </div>
      <div className="h-2" />
      <div className="flex justify-between">
        <div>
          <label className="text-lightGray text-nowrap">City</label>
        </div>
        <div>
          <input
            value={city}
            onChange={(e) => {
              setCity(e.target.value)
            }}
            className="flex-1 w-[8rem] text-[black]"
            type="text"
          />
        </div>
      </div>
      <div className="h-3" />
      <button onClick={handleFilter} className="bg-orange w-full text-14 py-1 text-center rounded-md cursor-pointer">
        Filter
      </button>
    </div>
  )
}

export default OrdersFilters
