import React from 'react'
import { ADD_POINT, REMOVE_POINT } from 'src/redux/routeReducer'
import { useDispatch } from 'react-redux'
import { Address } from 'src/types'
import WhatsAppCall from './WhatsAppCall'

interface Props {
  isPointAdded: (id: number) => boolean
  address: Address
  orderNumber: string
  bikeModel: string
}

const AddressCel = ({ isPointAdded, address, orderNumber, bikeModel }: Props): JSX.Element => {
  const isPointAddedToRoute = isPointAdded(address.id)
  const dispatch = useDispatch()
  return (
    <td
      style={{
        backgroundColor: isPointAddedToRoute ? '#eeeeee' : 'transparent',
        cursor: address.route === null ? 'pointer' : 'not-allowed',
        color: address.route === null ? '' : '#888888'
      }}
      onClick={() => {
        if (address.route !== null) {
          return
        }
        if (isPointAddedToRoute) {
          dispatch(REMOVE_POINT({ address_id: address.id }))
        } else {
          dispatch(
            ADD_POINT({
              ...address.point,
              address_id: address.id,
              orderNumber,
              zip: address.zip,
              phone: address.first_phone ?? '',
              name: address.name ?? '',
              id: address.id,
              bikeModel
            })
          )
        }
      }}
      className="p-2 border cursor-pointer border-[#f4f4f4] relative overflow-hidden">
      {address.zip}
      <div className="inline-block ml-2">
        <WhatsAppCall phone={address.first_phone} />
      </div>
      {isPointAddedToRoute && <div className="w-3 h-3 bg-orange absolute top-[-5px] right-[-5px] rotate-45" />}
    </td>
  )
}

export default AddressCel
