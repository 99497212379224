import React from 'react'
import { FieldMetaState, FieldInputProps } from 'react-final-form'
interface Props {
  label: string
  className: string
  inputType: string
  meta: FieldMetaState<string | undefined>
  input: FieldInputProps<string | undefined>
  disabled?: boolean
}

const OrderInput = ({ label, input, meta, inputType, disabled = false }: Props): JSX.Element => {
  const error = (meta.touched ?? false) && Boolean(meta.error) && meta.error.length > 0
  return (
    <div className={`flex-1 mt-3 cursor-${disabled ? 'bg-depGraphite' : 'bg-white'}`}>
      <label className="block font-semibold text-14">{label}</label>
      <input
        {...input}
        onChange={(e) => {
          if (!disabled) {
            input.onChange(e)
          }
        }}
        type={inputType}
        className={`border ${disabled ? 'cursor-not-allowed bg-[#eeeeee]' : ''} ${
          error ? 'border-[red]' : 'border-lightGray'
        } text-16 px-2 py-1 text-darkGrey flex-1 w-full`}
      />
    </div>
  )
}

export default OrderInput
