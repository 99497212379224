import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootReducer } from 'src/redux/rootReducer'
import { UPDATE_ORDER } from 'src/redux/orderReducer'
import { Order } from 'src/types'

interface Option {
  value: Order['status']
  label: string
}

const StatusSelect = (): JSX.Element => {
  const { id: orderId } = useParams()
  const dispatch = useDispatch()
  const orderStatus = useSelector((state: RootReducer) => state.orderReducer.orders[orderId ?? '0']?.status)
  if (typeof orderStatus !== 'string') {
    return <div>Loading...</div>
  }
  const options: Option[] = [
    { value: 'created', label: 'Erstellt' },
    { value: 'processed', label: 'Bearbeitet' },
    { value: 'partially_finished', label: 'Teilweise abgeschlossen' },
    { value: 'deleted', label: 'Gelöscht' },
    { value: 'finished', label: 'Abgeschlossen' },
    { value: 'archived', label: 'Archiviert' },
    { value: 'link_sent', label: 'Link gesendet' },
    { value: 'form_submitted', label: 'Formular eingereicht' },
    { value: 'in_transport', label: 'In Transport' }
  ]

  return (
    <select
      value={orderStatus}
      onChange={(e) => {
        dispatch(UPDATE_ORDER({ data: { status: e.target.value as Order['status'] }, orderId: Number(orderId) }))
      }}>
      {options.map((option) => (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  )
}

export default StatusSelect
