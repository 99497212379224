/* eslint-disable @typescript-eslint/no-explicit-any */
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Point } from 'src/redux/routeReducer'

interface Props {
  item: Point
  dragHandleProps: any
}

const RouteElement = ({ item, dragHandleProps }: Props): JSX.Element => {
  const { phone, name, zip, bikeModel, distance, duration } = item
  return (
    <div {...dragHandleProps} className="bg-white rounded-sm w-[20rem] border border-blue mb-1 p-3">
      <div className="flex flex-row justify-between">
        <p className="text-16 font-semibold">{bikeModel}</p>
        <p className="text-14 max-w-[9rem] text-right">
          {duration} {distance}
        </p>
      </div>
      <div className="h-[1rem]" />
      <div className="flex flex-row justify-between">
        <div>
          <div>
            <FontAwesomeIcon size="xs" icon={faPhone} />
          </div>
          <p className="text-14">{phone}</p>
        </div>
        <div>
          <div className="text-14 font-semibold">Name:</div>
          <p className="text-14">{name}</p>
        </div>
        <div>
          <div className="text-14 font-semibold">Zip:</div>
          <p className="text-14">{zip}</p>
        </div>
      </div>
    </div>
  )
}

export default RouteElement
