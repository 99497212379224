import React, { useEffect } from 'react'
import Layout from '../Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { FETCH_ROUTES, ARCHIVE_ROUTE } from 'src/redux/routesReducer'
import { RootReducer } from 'src/redux/rootReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import Pagination from '../molecules/Pagination'
import { useNavigate } from 'react-router-dom'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { formatDistance } from 'src/helpers/formatDistance'
import { formatDuration } from 'src/helpers/formatDuration'

const RoutesListScreen = (): JSX.Element => {
  const dispatch = useDispatch()
  const currentPage = useSelector((state: RootReducer) => state.routesReducer.currentPage)
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(FETCH_ROUTES())
  }, [dispatch, currentPage])

  const routes = useSelector((state: RootReducer) => state.routesReducer.routes)

  return (
    <Layout withFilter>
      <div className="p-3">
        <div className="flex items-center justify-between">
          <h1 className="text-24 ml-6">Routen</h1>
          <div
            onClick={() => {
              navigate('/routes/new')
            }}
            className="cursor-pointer mr-3 bg-white px-4 py-1 border-blue border-2 text-blue flex items-center flex-col">
            <FontAwesomeIcon icon={faPlus} size="lg" />
            <div>Neue</div>
          </div>
        </div>
        <div className="h-3" />
        <div className="bg-white rounded-sm w-full overflow-hidden">
          <div className="border-b-4 border-orange" />
          <div className="p-3">
            <table className="w-full text-14">
              <tr>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Id</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Name der Route</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Entfernung</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Dauer</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Erstellungsdatum</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold"></td>
              </tr>
              {routes.map(({ id, name, distance, duration, created_at }, idx) => (
                <tr
                  onClick={() => {
                    navigate(`/routes/${id}`)
                  }}
                  key={id}
                  className={`${idx % 2 === 0 ? 'bg-grayBackground' : 'bg-white'} cursor-pointer`}>
                  <td className="p-2 border border-[#f4f4f4]">{id}</td>
                  <td className="p-2 border border-[#f4f4f4]">{name}</td>
                  <td className="p-2 border border-[#f4f4f4]">{formatDistance(distance)}</td>
                  <td className="p-2 border border-[#f4f4f4]">{formatDuration(duration)}</td>
                  <td className="p-2 border border-[#f4f4f4]">{created_at}</td>
                  <td className="p-2 border border-[#f4f4f4]">
                    <div
                      onClick={(e) => {
                        e.stopPropagation()
                        const confirm = window.confirm('Bestätigen archiviert')
                        if (confirm) {
                          dispatch(ARCHIVE_ROUTE({ id }))
                        }
                      }}
                      className="flex-1 justify-center flex items-center cursor-pointer">
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
        <div className="h-4" />
        <Pagination reducer="routesReducer" />
      </div>
    </Layout>
  )
}

export default RoutesListScreen
