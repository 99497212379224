import React, { useRef } from 'react'
import RouteElement from '../atoms/RouteElement'
import DraggableList from 'react-draggable-list'
import { RootReducer } from 'src/redux/rootReducer'
import { useDispatch, useSelector } from 'react-redux'
import { Point, SET_ACTIVE_POINTS } from 'src/redux/routeReducer'
import RouteStaticElement from '../atoms/RouteStaticElement'

const RouteElements = (): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { activePoints } = useSelector((state: RootReducer) => state.routeReducer)
  const [lastPointDistance, lastPointDuration] = useSelector((state: RootReducer) => {
    const { directions } = state.routeReducer
    if (directions === null) {
      return ['', '']
    }
    const [{ legs }] = directions.routes
    const lastLeg = legs[legs.length - 1]
    return [lastLeg.distance?.text ?? '', lastLeg.duration?.text ?? '']
  })
  const dispatch = useDispatch()
  const onListChange = (newList: Point[]): void => {
    dispatch(SET_ACTIVE_POINTS(newList))
  }

  return (
    <>
      <RouteStaticElement type="start" />
      <div ref={containerRef}>
        <DraggableList
          itemKey="id"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error
          // @ts-ignore
          template={RouteElement}
          list={activePoints}
          onMoveEnd={(newList) => {
            onListChange(newList as Point[])
          }}
          container={() => containerRef.current}
        />
      </div>
      <RouteStaticElement type="end" duration={lastPointDuration} distance={lastPointDistance} />
    </>
  )
}

export default RouteElements
