import React from 'react'
import { Field } from 'react-final-form'
import { required } from 'src/helpers/required'
import OrderInput from '../atoms/OrderInput'
import FinalFormRatio from '../atoms/FinalFormRatio'
interface Props {
  prefix: 'from' | 'to' | 'invoice'
  title: string
}
const EditOrderAddress = ({ prefix, title }: Props): JSX.Element => {
  return (
    <div>
      <p className="text-22">{title}</p>
      <div className="bg-[white] px-5 border border-lightGray py-2">
        <div className="h-2" />
        <div className="flex gap-6">
          <FinalFormRatio
            name={`${prefix}_address_personal_type`}
            options={[
              { label: 'Herr', value: 'he' },
              { label: 'Frau', value: 'she' },
              { label: 'Firma', value: 'company' }
            ]}
          />
        </div>
        <div className="flex flex-wrap">
          <div className="w-full flex gap-6">
            <div className="min-w-[400px]">
              <Field
                validate={required}
                name={`${prefix}_address_name`}
                component={OrderInput}
                label="Firma/ Vor- und Nachname"
              />
            </div>
          </div>
          <div className="w-full flex gap-6">
            <div className="min-w-[250px]">
              <Field validate={required} name={`${prefix}_address_street`} component={OrderInput} label="Straße" />
            </div>
            <div className="w-12">
              <Field
                validate={required}
                name={`${prefix}_address_street_number`}
                component={OrderInput}
                label="Hausnummer"
              />
            </div>
          </div>
          <div className="w-full flex gap-6">
            <div className="w-20">
              <Field validate={required} name={`${prefix}_address_zip`} component={OrderInput} label="PLZ" />
            </div>
            <div className="min-w-[250px]">
              <Field validate={required} name={`${prefix}_address_city`} component={OrderInput} label="Stadt" />
            </div>
          </div>
          <div className="w-full flex gap-6">
            <div className="min-w-[100px]">
              <Field name={`${prefix}_address_email`} component={OrderInput} label="Email" />
            </div>
            <div className="min-w-[100px]">
              <Field name={`${prefix}_address_phone`} component={OrderInput} label="Mobiltelefonnummer" />
            </div>
            <div className="min-w-[100px]">
              <Field name={`${prefix}_address_second_phone`} component={OrderInput} label="2. Telefonnummer" />
            </div>
          </div>
          <div className="h-2" />
        </div>
      </div>
    </div>
  )
}

export default EditOrderAddress
