import React from 'react'
import { AdvancedMarker, Pin } from '@vis.gl/react-google-maps'
import { useSelector, useDispatch } from 'react-redux'
import { RootReducer } from 'src/redux/rootReducer'
import { ACTIVATE_POINT, DEACTIVATE_POINT } from 'src/redux/routeReducer'
import { baseLatLong } from 'src/sagas/FetchDirectionsSaga'

const MapMarkers = (): JSX.Element => {
  const points = useSelector((state: RootReducer) => state.routeReducer.points)
  const activePoints = useSelector((state: RootReducer) => state.routeReducer.activePoints)
  const dispatch = useDispatch()
  return (
    <>
      {points.map((point) => (
        <AdvancedMarker
          onClick={() => {
            dispatch(ACTIVATE_POINT({ address_id: point.address_id }))
          }}
          key={point.lat + point.lng}
          position={point}>
          <Pin background={'gray'} glyphColor={'white'} borderColor={'gray'} />
        </AdvancedMarker>
      ))}
      {activePoints.map((point) => (
        <AdvancedMarker
          onClick={() => {
            dispatch(DEACTIVATE_POINT({ address_id: point.address_id }))
          }}
          key={point.lat + point.lng}
          position={point}>
          <Pin background="red" glyphColor={'white'} borderColor={'red'} />
        </AdvancedMarker>
      ))}
      <AdvancedMarker position={baseLatLong}>
        <Pin background="blue" glyphColor={'white'} borderColor={'blue'} />
      </AdvancedMarker>
    </>
  )
}

export default MapMarkers
