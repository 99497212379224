import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { GET } from 'src/consts/methods'
import {
  FETCH_ORDERS_FOR_PLANING,
  FETCH_ORDERS_FOR_PLANING_FAIL,
  FETCH_ORDERS_FOR_PLANING_SUCCESS
} from 'src/redux/routeReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* FetchOrdersForRoutePlaningFlow(): Generator<any, void, any> {
  try {
    const response = yield call(apiCall, {
      endpoint: '/admin/orders/for_route',
      method: GET,
      params: {}
    })
    yield put(FETCH_ORDERS_FOR_PLANING_SUCCESS(response))
  } catch (error) {
    yield put(FETCH_ORDERS_FOR_PLANING_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* FetchOrdersForRoutePlaningSaga(): Generator<any, void, any> {
  yield takeLatest(FETCH_ORDERS_FOR_PLANING, FetchOrdersForRoutePlaningFlow)
}
