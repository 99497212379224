import React from 'react'
import { Field } from 'react-final-form'
import { required } from 'src/helpers/required'
import OrderInput from '../atoms/OrderInput'

const EditOrderMainData = (): JSX.Element => {
  return (
    <div>
      <p className="text-22">Auftraggeber</p>
      <div className="bg-[white] px-5 border border-lightGray py-2">
        <div className="h-2" />
        <div className="flex flex-wrap">
          <div className="w-full flex gap-6">
            <div className="min-w-[200px]">
              <Field validate={required} name="first_name" component={OrderInput} label="Vorname" />
            </div>
            <div className="min-w-[200px]">
              <Field validate={required} name="last_name" component={OrderInput} label="Nachname" />
            </div>
          </div>
          <div className="w-full flex gap-6">
            <div className="min-w-[200px]">
              <Field validate={required} name="email" component={OrderInput} label="Email" />
            </div>
            <div className="min-w-[200px]">
              <Field validate={required} name="phone" component={OrderInput} label="Telefon" />
            </div>
          </div>
          <div className="w-full flex gap-6">
            <div className="min-w-[200px]">
              <Field validate={required} name="mark" component={OrderInput} label="Marke" />
            </div>
            <div className="min-w-[200px]">
              <Field validate={required} name="bike_model" component={OrderInput} label="Modell" />
            </div>
          </div>
          <div className="h-2" />
        </div>
      </div>
    </div>
  )
}

export default EditOrderMainData
