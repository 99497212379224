import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OPEN_MODAL } from 'src/redux/emailModalReducer'
import { FETCH_EMAIL_TEMPLATES } from 'src/redux/emailTemplatesReducer'
import { RootReducer } from 'src/redux/rootReducer'
import { useParams } from 'react-router-dom'
import { PersonalizedEmailTemplateType } from 'src/types'
import Email from '../atoms/Email'
import { FETCH_EMAILS } from 'src/redux/emailsReducer'

const buttonNameMapper = (name: string): string => {
  switch (name) {
    case 'send_link':
      return 'Link senden'
    case 'later':
      return 'für später'
    case 'i_dont_care':
      return 'beschäftigen uns nicht'
    case 'more_details':
      return 'mehr Details'
    case 'empty_message':
      return 'leere Nachricht'
    case 'send_invoice':
      return 'Rechnung senden'
    case 'list_page_title':
      return 'Vorlagenliste'
    case 'name':
      return 'Name'
    case 'title':
      return 'Titel'
    case 'edit_title':
      return 'Vorlage'
    default:
      return name.replace(/_/g, ' ')
  }
}

const EmailsSection = (): JSX.Element => {
  const { id: orderId } = useParams()
  const orderNumber = useSelector((state: RootReducer) => state.orderReducer.orders[orderId ?? '0']?.order_number)
  const emails = useSelector((state: RootReducer) => state.emailsReducer.emails[orderNumber ?? ''])

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(FETCH_EMAIL_TEMPLATES())
  }, [dispatch])

  useEffect(() => {
    if (orderNumber?.length > 0) {
      dispatch(FETCH_EMAILS({ orderNumber }))
    }
  }, [dispatch, orderNumber])

  const emailTemplates = useSelector((state: RootReducer) => state.emailTemplatesReducer.email_templates)
  return (
    <div className="px-2 flex-1 max-w-[1000px] min-w-[500px] ">
      <div className="p-2 bg-darkGrey flex flex-row gap-2 flex-wrap">
        {emailTemplates.map((item) => (
          <button
            onClick={() => {
              dispatch(
                OPEN_MODAL({
                  modalType: item.name as PersonalizedEmailTemplateType,
                  orderId: parseInt(orderId ?? '0', 10)
                })
              )
            }}
            key={item.id}
            className="bg-white border-2 border-blue rounded-md px-2 py-1">
            {buttonNameMapper(item.name)}
          </button>
        ))}
      </div>
      {typeof emails === 'object' && (
        <>
          {emails.map((email) => (
            <Email key={email.id} email={email.from} date={email.date} content={email.body} />
          ))}
        </>
      )}
    </div>
  )
}

export default EmailsSection
