import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducer } from 'src/redux/rootReducer'
import { FETCH_ORDERS_FOR_PLANING } from 'src/redux/routeReducer'
import { useNavigate } from 'react-router-dom'
import AddressCel from '../atoms/AddressCel'
import SubmittedFormDataCell from '../atoms/SubmittedFormDateCell'
import getOrderListBorderColor from 'src/helpers/getOrderListBorderColor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons'

const RoutePlaningOrderList = (): JSX.Element => {
  const orders = useSelector((state: RootReducer) => state.routeReducer.orders)
  const points = useSelector((state: RootReducer) => state.routeReducer.points)
  const activePoints = useSelector((state: RootReducer) => state.routeReducer.activePoints)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isPointAdded = (id: number): boolean => {
    return [...points, ...activePoints].some((point) => point.id === id)
  }

  useEffect(() => {
    dispatch(FETCH_ORDERS_FOR_PLANING())
  }, [dispatch])
  return (
    <div>
      <div className="bg-white rounded-sm w-full overflow-hidden">
        <div className="border-b-4 border-orange" />
        <div className="p-3">
          <table className="w-full text-14">
            <tr>
              <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Marke</td>
              <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">PLZ(start)</td>
              <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">PLZ(ende)</td>
              <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Beauftragt</td>
            </tr>
            {orders.map(
              (
                {
                  bike_model,
                  mark,
                  id,
                  from_address,
                  to_address,
                  change_to_submitted_form_date,
                  order_number,
                  created_by,
                  make_payment
                },
                idx
              ) => (
                <tr key={id} className={`${idx % 2 === 0 ? 'bg-[#f9f9f9]' : 'bg-white'}`}>
                  <td
                    onClick={() => {
                      navigate(`/orders/${id}`)
                    }}
                    style={{
                      borderLeft: `2px solid ${getOrderListBorderColor(created_by ?? 'customer')}`
                    }}
                    className="p-2 border border-[#f4f4f4] cursor-pointer">
                    {mark} {bike_model} {make_payment === true && <FontAwesomeIcon icon={faMoneyBill} />}
                  </td>
                  <AddressCel
                    isPointAdded={isPointAdded}
                    address={from_address}
                    orderNumber={order_number}
                    bikeModel={bike_model}
                  />
                  <AddressCel
                    isPointAdded={isPointAdded}
                    address={to_address}
                    orderNumber={order_number}
                    bikeModel={bike_model}
                  />
                  <SubmittedFormDataCell submittedFormDate={change_to_submitted_form_date} />
                </tr>
              )
            )}
          </table>
        </div>
      </div>
    </div>
  )
}

export default RoutePlaningOrderList
