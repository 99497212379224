import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { GET } from 'src/consts/methods'
import { FETCH_STATISTICS, FETCH_STATISTICS_FAIL, FETCH_STATISTICS_SUCCESS } from 'src/redux/statisticsReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* FetchStatisticsFlow(): Generator<any, void, any> {
  try {
    const response = yield call(apiCall, {
      endpoint: '/admin/orders/statistics',
      method: GET,
      params: {}
    })
    yield put(FETCH_STATISTICS_SUCCESS(response))
  } catch (error) {
    yield put(FETCH_STATISTICS_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* FetchStatisticsSaga(): Generator<any, void, any> {
  yield takeLatest(FETCH_STATISTICS, FetchStatisticsFlow)
}
