import { PersonalType } from '../types'

export const personalTypeMapper = (type: PersonalType): string => {
  switch (type) {
    case 'he':
      return 'Herr'
    case 'she':
      return 'Frau'
    case 'company':
      return 'Firma'
    default:
      return 'Unknown'
  }
}
