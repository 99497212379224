import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducer } from 'src/redux/rootReducer'
import { SET_ROUTE_NAME } from 'src/redux/routeReducer'

const RouteTitleInput = (): JSX.Element => {
  const dispatch = useDispatch()
  const title = useSelector((state: RootReducer) => state.routeReducer.routeName)
  return (
    <div>
      <input
        type="text"
        value={title}
        className="border-2 border-gray-300 rounded-md p-2"
        onChange={(e) => {
          dispatch(SET_ROUTE_NAME(e.target.value))
        }}
      />
    </div>
  )
}

export default RouteTitleInput
