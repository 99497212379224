import { call, put, select, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { GET } from 'src/consts/methods'
import { RootReducer } from 'src/redux/rootReducer'
import { FETCH_ORDERS, FETCH_ORDERS_FAIL, FETCH_ORDERS_SUCCESS } from 'src/redux/ordersReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* FetchOrdersFlow(): Generator<any, void, any> {
  try {
    const currentPage = yield select((state: RootReducer) => state.ordersReducer.currentPage)
    const sort = yield select((state: RootReducer) => state.ordersReducer.sort)
    const search = yield select((state: RootReducer) => state.ordersReducer.search)
    const response = yield call(apiCall, {
      endpoint: '/admin/orders',
      method: GET,
      params: { page: currentPage, sort, search }
    })
    yield put(FETCH_ORDERS_SUCCESS(response))
  } catch (error) {
    yield put(FETCH_ORDERS_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* FetchOrdersSaga(): Generator<any, void, any> {
  yield takeLatest(FETCH_ORDERS, FetchOrdersFlow)
}
