import { put, select, takeLatest } from 'redux-saga/effects'
import { RootReducer } from 'src/redux/rootReducer'
import { Point, SET_ACTIVE_POINTS, UPDATE_POINTS } from 'src/redux/routeReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* UpdatePointsFlow(): Generator<any, void, any> {
  try {
    const activePoints = yield select((state: RootReducer) => state.routeReducer.activePoints)
    const directions = yield select((state: RootReducer) => state.routeReducer.directions)
    const [{ legs }] = directions.routes

    const newActivePoints = activePoints.map((point: Point, index: number) => {
      const leg = legs[index]
      return {
        ...point,
        distance: leg.distance.text,
        duration: leg.duration.text
      }
    })
    yield put(SET_ACTIVE_POINTS(newActivePoints))
  } catch (error) {}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* UpdatePointsSaga(): Generator<any, void, any> {
  yield takeLatest(UPDATE_POINTS, UpdatePointsFlow)
}
