/* eslint-disable @typescript-eslint/ban-types */
import { createReducer, createAction } from '@reduxjs/toolkit'
export interface HeaderReducer {
  title: string
}

const initialState = {
  title: ''
}

export interface SignInPayload {
  email: string
  password: string
}

interface SetTitlePayload {
  title: string
}
export const SET_TITLE = createAction<SetTitlePayload>('SET_TITLE')

export const headerReducer = createReducer(initialState, (builder) => {
  builder.addCase(SET_TITLE, (state, action) => {
    return { ...state, title: action.payload.title }
  })
})

export default headerReducer
