import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { PUT } from 'src/consts/methods'
import { ResetOrderActiontype, RESET_ORDER, RESET_ORDER_SUCCESS, RESET_ORDER_FAIL } from 'src/redux/orderReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* ResetOrderFlow({ payload }: ResetOrderActiontype): Generator<any, void, any> {
  try {
    const response = yield call(apiCall, {
      endpoint: `/admin/orders/${payload.id}/reset`,
      method: PUT,
      params: {}
    })
    yield put(RESET_ORDER_SUCCESS(response))
  } catch (error) {
    yield put(RESET_ORDER_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* ResetOrderSaga(): Generator<any, void, any> {
  yield takeLatest(RESET_ORDER, ResetOrderFlow)
}
