import React from 'react'
import { Form, Field } from 'react-final-form'
import { CreateInvoicePayload } from 'src/redux/invoiceReducer'
import InvoiceInput from '../atoms/InvoiceInput'
interface Props {
  initialFormValues: CreateInvoicePayload
  onSubmit: (values: CreateInvoicePayload) => void
  isLoading: boolean
  withMakePaymentField?: boolean
  type?: 'new' | 'edit'
}

const InvoiceForm = ({
  initialFormValues,
  onSubmit,
  isLoading,
  type = 'new',
  withMakePaymentField = false
}: Props): JSX.Element => {
  const required = (value: string | undefined): string | undefined => {
    // eslint-disable-next-line no-undefined, @typescript-eslint/strict-boolean-expressions
    return value ? undefined : 'Required'
  }

  return (
    <Form
      initialValues={initialFormValues}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <form onSubmit={handleSubmit}>
          <div>Kunde ausgewählt</div>
          <div className="h-3" />
          <div>Datum der Auftragserteilung</div>
          <div className="h-3" />
          <div className="flex flex-wrap max-w-[40rem] gap-5">
            <div>
              <label className="text-16 block">Leistungsdatum</label>
              <Field name="execution_date" type="date" component={InvoiceInput} inputType="date" />
            </div>
            <div>
              <label className="text-16 block">Price gross</label>
              <Field validate={required} name="price_gross" component={InvoiceInput} inputType="number" />
            </div>
            {withMakePaymentField && (
              <div>
                <label className="text-16 block">Treuhand</label>
                <Field name="make_payment_gross" component={InvoiceInput} inputType="number" />
              </div>
            )}
            <div>
              <label className="text-16 block">Zip from</label>
              <Field validate={required} name="zip_from" component={InvoiceInput} inputType="number" />
            </div>
            <div>
              <label className="text-16 block">Zip to</label>
              <Field validate={required} name="zip_to" component={InvoiceInput} inputType="number" />
            </div>
            <div>
              <label className="text-16 block">Mark</label>
              <Field validate={required} name="mark" component={InvoiceInput} inputType="text" />
            </div>
            <div>
              <label className="text-16 block">Bike model</label>
              <Field validate={required} name="bike_model" component={InvoiceInput} inputType="text" />
            </div>
            <div>
              <label className="text-16 block">Tax number</label>
              <Field name="tax_number" component={InvoiceInput} inputType="text" />
            </div>
            <div>
              <label className="text-16 block">Payment method</label>
              <label htmlFor="cash" className="text-16 block">
                <Field
                  validate={required}
                  name="payment_method"
                  component="input"
                  className="mr-1"
                  id="cash"
                  type="radio"
                  value="cash"
                />
                Bar erhalten
              </label>

              <label htmlFor="transfer" className="text-16 block">
                <Field
                  validate={required}
                  name="payment_method"
                  component="input"
                  className="mr-1"
                  id="transfer"
                  type="radio"
                  value="transfer"
                />
                überwiesen am
              </label>
              <label htmlFor="transfer_instant" className="text-16 block">
                <Field
                  validate={required}
                  name="payment_method"
                  component="input"
                  className="mr-1"
                  id="transfer_instant"
                  type="radio"
                  value="transfer_instant"
                />
                Sofort zahlbar per Überweisung
              </label>
            </div>

            <div>
              <label className="text-16 block">überwiesen am date</label>
              <Field name="transferred_on" component={InvoiceInput} inputType="date" />
            </div>
            <div>
              <label className="text-16 block">Without tax</label>
              <Field name="without_tax" component={InvoiceInput} inputType="checkbox" />
            </div>
          </div>
          <div className="h-3" />
          <div className="h-3" />
          <div>Kundendaten</div>
          <div className="h-3" />
          <div className="flex flex-wrap max-w-[40rem] gap-5">
            <div>
              <label className="text-16 block">Company name</label>
              <Field
                validate={required}
                name="company_name"
                component={InvoiceInput}
                inputType="text"
                className="w-[20rem]"
              />
            </div>
            <div className="w-20" />
            <div>
              <label className="text-16 block">Street</label>
              <Field
                validate={required}
                name="street"
                component={InvoiceInput}
                inputType="text"
                className="w-[17rem]"
              />
            </div>
            <div>
              <label className="text-16 block">Street number</label>
              <Field
                validate={required}
                name="street_number"
                component={InvoiceInput}
                inputType="text"
                className="w-[17rem]"
              />
            </div>
            <div>
              <label className="text-16 block">Zip</label>
              <Field validate={required} name="zip" component={InvoiceInput} inputType="number" className="w-[17rem]" />
            </div>
            <div>
              <label className="text-16 block">City</label>
              <Field validate={required} name="city" component={InvoiceInput} inputType="text" className="w-[17rem]" />
            </div>
            <div className="w-[35.5rem]">
              <label className="text-16 block">Description</label>
              <Field
                validate={required}
                name="description"
                component={InvoiceInput}
                className="w-full"
                inputType="textarea"
              />
            </div>
          </div>
          <div className="h-3" />
          <button
            disabled={isLoading}
            type="submit"
            className="bg-orange text-14 py-2 px-10 text-center rounded-md cursor-pointer disabled:cursor-not-allowed">
            {type === 'edit' ? 'Aktualisieren' : 'Erstellen'}
          </button>
        </form>
      )}
    />
  )
}

export default InvoiceForm
