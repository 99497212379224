import { createReducer, createAction, PayloadAction } from '@reduxjs/toolkit'
import { OrderForRoutePlanning, RoutePlaningRoute } from '../types/index'
export interface Point {
  lat: number
  lng: number
  address_id: number
  orderNumber: string
  zip: string
  phone: string
  name: string
  id: number
  bikeModel: string
  distance?: string
  duration?: string
}

export interface RouteReducer {
  orders: OrderForRoutePlanning[]
  points: Point[]
  activePoints: Point[]
  directions: google.maps.DirectionsResult | null
  routeName: string
}

const initialState: RouteReducer = {
  orders: [],
  points: [],
  activePoints: [],
  directions: null,
  routeName: 'title'
}

export const ADD_POINT = createAction<Point>('route_reducer/add_point')
export const REMOVE_POINT = createAction<{ address_id: number }>('route_reducer/remove_point')

export const FETCH_ORDERS_FOR_PLANING = createAction('route_reducer/fetch_orders_for_planing')
export const FETCH_ORDERS_FOR_PLANING_SUCCESS = createAction<OrderForRoutePlanning[]>(
  'route_reducer/fetch_orders_for_planing_success'
)
export const FETCH_ORDERS_FOR_PLANING_FAIL = createAction('route_reducer/fetch_orders_for_planing_fail')

export const FETCH_DIRECTIONS = createAction('route_reducer/fetch_directions')
interface FetchDirectionsSuccessPayload {
  directions: google.maps.DirectionsResult
}
export const FETCH_DIRECTIONS_SUCCESS = createAction<FetchDirectionsSuccessPayload>(
  'route_reducer/fetch_directions_success'
)
export const FETCH_DIRECTIONS_FAIL = createAction('route_reducer/fetch_directions_fail')

interface ActivatePointPayload {
  address_id: number
}
export const ACTIVATE_POINT = createAction<ActivatePointPayload>('route_reducer/activate_point')
export const DEACTIVATE_POINT = createAction<ActivatePointPayload>('route_reducer/deactivate_point')
export const SET_ACTIVE_POINTS = createAction<Point[]>('route_reducer/set_active_points')
export const UPDATE_POINTS = createAction('route_reducer/update_points')

export interface CreateOrUpdateRoutePayload {
  routeId?: number
  withNotification: boolean
  withStatusChange: boolean
}
export type CreateOrUpdateRouteActionType = PayloadAction<CreateOrUpdateRoutePayload>
export const CREATE_OR_UPDATE_ROUTE = createAction<CreateOrUpdateRoutePayload>('route_reducer/create_or_update_route')
export const CREATE_OR_UPDATE_ROUTE_SUCCESS = createAction('route_reducer/create_or_update_route_success')
export const CREATE_OR_UPDATE_ROUTE_FAIL = createAction('route_reducer/create_or_update_route_fail')

interface FetchRoutePayload {
  id: number
}
export type FetchRouteActionType = PayloadAction<FetchRoutePayload>
export const FETCH_ROUTE = createAction<FetchRoutePayload>('route_reducer/fetch_route')
export const FETCH_ROUTE_SUCCESS = createAction<RoutePlaningRoute>('route_reducer/fetch_route_success')
export const FETCH_ROUTE_FAIL = createAction('route_reducer/fetch_route_fail')

export const RESET_ROUTE_REDUCER = createAction('route_reducer/reset_route_reducer')
export const SET_ROUTE_NAME = createAction<string>('route_reducer/set_route_name')

export const routeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(FETCH_ORDERS_FOR_PLANING_SUCCESS, (state, action) => {
      return { ...state, ...action.payload }
    })
    .addCase(ADD_POINT, (state, action) => {
      return { ...state, points: [...state.points, action.payload] }
    })
    .addCase(REMOVE_POINT, (state, action) => {
      const otherPoints = state.points.filter((item) => item.address_id !== action.payload.address_id)
      const otherActivePoints = state.activePoints.filter((item) => item.address_id !== action.payload.address_id)
      return { ...state, points: otherPoints, activePoints: otherActivePoints }
    })
    .addCase(ACTIVATE_POINT, (state, action) => {
      const point = state.points.find((item) => item.address_id === action.payload.address_id)
      const otherPoints = state.points.filter((item) => item.address_id !== action.payload.address_id)
      if (typeof point !== 'undefined') {
        return { ...state, activePoints: [...state.activePoints, point], points: otherPoints }
      }
      return state
    })
    .addCase(DEACTIVATE_POINT, (state, action) => {
      const point = state.activePoints.find((item) => item.address_id === action.payload.address_id)
      const otherPoints = state.activePoints.filter((item) => item.address_id !== action.payload.address_id)
      if (typeof point !== 'undefined') {
        return { ...state, activePoints: otherPoints, points: [...state.points, point] }
      }
      return state
    })
    .addCase(SET_ACTIVE_POINTS, (state, action) => {
      return { ...state, activePoints: action.payload }
    })
    .addCase(FETCH_DIRECTIONS_SUCCESS, (state, action) => {
      return { ...state, directions: action.payload.directions }
    })
    .addCase(FETCH_ROUTE_SUCCESS, (state, action) => {
      return { ...state, ...action.payload, activePoints: action.payload.active_points, routeName: action.payload.name }
    })
    .addCase(RESET_ROUTE_REDUCER, () => {
      return initialState
    })
    .addCase(SET_ROUTE_NAME, (state, action) => {
      return { ...state, routeName: action.payload }
    })
})

export default routeReducer
